import axios from "axios";
import { requestError } from "../utils/RequestError";

export const getListUser = async (params) => {
  try {
    const res = await axios.get(`/admin/users`, { params });
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const getUserById = async (id) => {
  try {
    const res = await axios.get(`/admin/users/${id}`);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};

export const updateUser = async (id, payload) => {
  try {
    const res = await axios.put(`/admin/users/${id}`, payload);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const approveUser = async (id,payload) => {
  try {
    const res = await axios.patch(`/admin/users/${id}/active`, payload);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const deleteUser = async (id) => {
  try {
    const res = await axios.delete(`/admin/users/${id}`);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
