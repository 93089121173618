import { Block, PaginationComponent, PreviewAltCard } from "../Component";
import ListSkeletonLoading from "../skeleton/ListSkeletonLoading";
import { DataTableHead, DataTableItem, DataTableRow } from "./DataTable";
import React from "react";

export default function BasePaginationTable({
  loading,
  list,
  columns,
  limit,
  page,
  onRowClick,
  totalItems,
  onPageChange,
  hasNextPage,
}) {
  return (
    <Block>
      <div className="nk-tb-list is-separate is-medium mb-3">
        <DataTableHead className="nk-tb-item">
          {columns.map((column, index) => (
            <DataTableRow key={index} className={column.headClassName}>
              {column.head}
            </DataTableRow>
          ))}
        </DataTableHead>

        {loading ? (
          <ListSkeletonLoading limit={limit} columns={columns} />
        ) : (
          list &&
          list.length > 0 &&
          list.map((item, index) => (
            <DataTableItem
              key={index}
              className={onRowClick ? "cursor-pointer" : null}
              onClick={onRowClick ? () => onRowClick(item) : null}
            >
              {columns.map((column, index) => (
                <DataTableRow
                  key={index}
                  onClick={column.onClick ? () => column.onClick(item) : null}
                  className={column.cellClassName}
                >
                  {column.cell(item)}
                </DataTableRow>
              ))}
            </DataTableItem>
          ))
        )}
      </div>
      {list && list?.length === 0 && !loading ? (
        <PreviewAltCard>
          <div className="text-center">
            <span className="text-gray">{"No Data Found"}</span>
          </div>
        </PreviewAltCard>
      ) : page === 1 && !hasNextPage ? null : (
        <PreviewAltCard>
          <PaginationComponent
            totalItems={totalItems}
            itemPerPage={limit ?? 10}
            paginate={onPageChange}
            currentPage={page ?? 1}
          />
        </PreviewAltCard>
      )}
    </Block>
  );
}
