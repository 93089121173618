import axios from "axios";
import { requestError } from "../utils/RequestError";

export const getListProduct = async (params) => {
  try {
    const res = await axios.get(`/admin/products`, { params });
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const getProductById = async (id) => {
  try {
    const res = await axios.get(`/admin/products/${id}`);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const updateProduct = async (id, payload) => {
  try {
    const res = await axios.put(`/admin/products/${id}`, payload);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const deleteProduct = async (id) => {
  try {
    const res = await axios.delete(`/admin/products/${id}`);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
