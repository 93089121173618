import { createSlice } from "@reduxjs/toolkit";
import * as PostApi from "../api/post";

const postDetail = createSlice({
  name: "postDetail",
  initialState: {
    loading: false,
    error: null,
    post: null,
  },
  reducers: {
    setLoading: (state) => {
      state.error = null;
      state.loading = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    getDetailSuccess: (state, action) => {
      state.post = action.payload;
      state.loading = false;
    },
    updatePostSuccess: (state, action) => {
      state.post = action.payload;
      state.loading = false;
    },
  },
});

export const getPostDetail = (id) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await PostApi.getPostById(id);
    dispatch(getDetailSuccess(res));
  } catch (error) {
    dispatch(setError(error));
  }
};

const { reducer, actions } = postDetail;
export const { setLoading, setError, getDetailSuccess, updatePostSuccess } =
  actions;
export default reducer;
