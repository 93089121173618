import React, { useEffect } from "react";
import { Modal, ModalBody, Form } from "reactstrap";
import { Icon, Col, Button } from "../../../components/Component";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { roles } from "../../../constants";

const UpdateAdminModal = ({ isOpen, closeModal, admin }) => {
  const schema = yup
    .object({
      name: yup.string().required("Name is required"),
      email: yup
        .string()
        .required("Email is required")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          "Invalid email address"
        ),
      password: yup
        .string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Password must match"),

      role: yup.string(),
      address: yup.string().required("Address is required"),
      modules: yup
        .array()
        .required("Modules is required")
        .min(1, "Modules is required"),
    })
    .required();
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    console.log(data);
  };
  useEffect(() => {
    reset(admin);
  }, [reset, admin]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeModal()}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{admin != null ? "Update" : "Add"} Admin</h5>
          <div className="mt-4">
            <Form
              className="row gy-4 form-validate is-alter"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      {...register("name", {
                        required: "This field is required",
                      })}
                      placeholder="Enter name"
                    />
                    {errors.name && (
                      <span className="invalid">{errors.name.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Email</label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      {...register("email")}
                      placeholder="Enter email address"
                    />
                    {errors.email && (
                      <span className="invalid">{errors.email.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Password</label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="password"
                      {...register("password")}
                      placeholder="Enter password"
                    />
                    {errors.password && (
                      <span className="invalid">{errors.password.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Confirm Password</label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      {...register("confirmPassword")}
                      placeholder="Enter confirm password"
                    />
                    {errors.confirmPassword && (
                      <span className="invalid">
                        {errors.confirmPassword.message}
                      </span>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Role</label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <select
                        className="form-control form-select"
                        {...register("role")}
                        placeholder="Select a option"
                      >
                        <option label="All"></option>
                        {roles.map((location, i) => (
                          <option key={i} label={location} value={location}>
                            {location}
                          </option>
                        ))}
                      </select>
                      {errors.location && (
                        <span className="invalid">
                          {errors.location.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Address</label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      {...register("address")}
                      placeholder="Enter address"
                    />
                    {errors.address && (
                      <span className="invalid">{errors.address.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      Save
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default UpdateAdminModal;
