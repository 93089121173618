import React, { useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { changePassword } from "../../api/auth";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [errorMsg, setErrorMsg] = useState();
  const [currentPassState, setCurrentPassState] = useState(false);
  const [newPassState, setNewPassState] = useState(false);

  const schema = yup
    .object({
      currentPassword: yup.string().required("Password is required"),
      newPassword: yup.string().required("Password is required"),
    })
    .required();

  const onFormSubmit = async (payload) => {
    try {
      setErrorMsg();
      await changePassword(payload);
      toast.success("Change password successfully");
    } catch (error) {
      setErrorMsg(error);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });
  return (
    <>
      <Head title="Change Password" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={"/"} className="logo-link">
            <img
              className="logo-light logo-img logo-img-lg"
              src={Logo}
              alt="logo"
            />
            <img
              className="logo-dark logo-img logo-img-lg"
              src={LogoDark}
              alt="logo-dark"
            />
          </Link>
        </div>

        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h4">Change Password</BlockTitle>
            </BlockContent>
          </BlockHead>
          {errorMsg && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                <Icon name="alert-circle" />
                {errorMsg}
              </Alert>
            </div>
          )}
          <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="password">
                  Current Password
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setCurrentPassState(!currentPassState);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${
                    currentPassState ? "is-hidden" : "is-shown"
                  }`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                  <Icon
                    name="eye-off"
                    className="passcode-icon icon-hide"
                  ></Icon>
                </a>
                <input
                  type={currentPassState ? "text" : "password"}
                  id="password"
                  {...register("currentPassword")}
                  placeholder="Enter your current password"
                  className={`form-control-lg form-control ${
                    currentPassState ? "is-hidden" : "is-shown"
                  }`}
                />
                {errors.currentPassword && (
                  <span className="invalid">{errors.currentPassword.message}</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="new-password">
                 New Password
                </label>
              </div>
              <div className="form-control-wrap">
                <a
                  href="#new-password"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setNewPassState(!newPassState);
                  }}
                  className={`form-icon lg form-icon-right passcode-switch ${
                    newPassState ? "is-hidden" : "is-shown"
                  }`}
                >
                  <Icon name="eye" className="passcode-icon icon-show"></Icon>

                  <Icon
                    name="eye-off"
                    className="passcode-icon icon-hide"
                  ></Icon>
                </a>
                <input
                  type={newPassState ? "text" : "password"}
                  id="new-password"
                  {...register("newPassword")}
                  placeholder="Enter your new password"
                  className={`form-control-lg form-control ${
                    newPassState ? "is-hidden" : "is-shown"
                  }`}
                />
                {errors.newPassword && (
                  <span className="invalid">{errors.newPassword.message}</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <Button
                size="lg"
                className="btn-block"
                type="submit"
                color="primary"
              >
                {isSubmitting ? <Spinner size="sm" color="light" /> : "Sign in"}
              </Button>
            </div>
          </Form>
        </PreviewCard>
      </Block>
    </>
  );
};
export default ChangePassword;
