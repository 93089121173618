import React, { useEffect } from "react";
import { Modal, ModalBody, Form, Label, Spinner } from "reactstrap";
import { Icon, Col, Button } from "../../../components/Component";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { categoryTypes } from "../../../constants";
import { addCategory, updateCategory } from "../../../api/category";
import {
  addCategorySuccess,
  updateCategorySuccess,
} from "../../../slice/categories";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const UpdateCategoryModal = ({ isOpen, closeModal, category }) => {
  const schema = yup
    .object({
      name: yup.string().required("Name is required"),
      type: yup.string().required("Type is required"),
    })
    .required();
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    if (category) {
      await onUpdateCategory(data);
    } else {
      await onAddCategory(data);
    }
  };
  useEffect(() => {
    reset({
      name: category?.name,
      type: category?.type,
    });
  }, [reset, category]);
  const dispatch = useDispatch();
  const onAddCategory = async (data) => {
    try {
      const res = await addCategory(data);
      dispatch(addCategorySuccess(res));
      toast.success("Add category successfully");
      closeModal();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const onUpdateCategory = async (data) => {
    try {
      const res = await updateCategory(category._id, data);
      dispatch(updateCategorySuccess(res));
      toast.success("Update category successfully");
      closeModal();
    } catch (error) {
      toast.error(error?.message);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeModal()}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">
            {category != null ? "Update" : "Add"} Category
          </h5>
          <div className="mt-4">
            <Form
              className="row gy-4 form-validate is-alter"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      {...register("name")}
                      placeholder="Enter name"
                    />
                    {errors.name && (
                      <span className="invalid">{errors.name.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-role">
                    Type
                  </Label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <select
                        className="form-control form-select"
                        id="fv-role"
                        {...register("type")}
                        placeholder="Select a option"
                      >
                        {categoryTypes.map((e, i) => (
                          <option key={i} label={e.label} value={e.value}>
                            {e.label}
                          </option>
                        ))}
                      </select>
                      {errors.type && (
                        <span className="invalid">{errors.type.message}</span>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {isSubmitting ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default UpdateCategoryModal;
