import Router from "./route/Index";
import OneSignal from 'react-onesignal';
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import ThemeProvider from "./layout/provider/Theme";
import { configAxios } from "./utils/Axios";
import { ToastContainer } from "react-toastify";
configAxios(store);

const App = () => {
  OneSignal.init({
    appId: "7e45eb76-f8e0-4370-b8e3-38e88c13e7ee",
  }).then(() => {
    // OneSignal.Debug.setLogLevel('trace');
    // OneSignal.login("rodrigo");
    // OneSignal.User.addAlias("myAlias", "1");
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <Router />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover={false}
            delay={false}
            theme="light"
          />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};
export default App;
