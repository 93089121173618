import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { debounce } from "lodash";
import {
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  Icon,
} from "../../components/Component";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import BasePaginationTable from "../../components/table/BasePaginationTable";
import moment from "moment";
import DeleteConfirmationModal from "../admin/modals/DeleteConfimationModal";
import { useDispatch } from "react-redux";
import { defaultParams } from "../../constants";
import { useSelector } from "react-redux";
import { deleteReportSuccess, getListReport } from "../../slice/reports";
import { deleteReport } from "../../api/report";
import { toast } from "react-toastify";

function getDefaultColumns(onDeleteClick) {
  return [
    {
      head: <span className="sub-text">{"Type"}</span>,
      cell: (row) => <span>{row.type}</span>,
    },
    {
      head: <span className="sub-text">{"Report content"}</span>,
      cell: (row) => <span>{row.content}</span>,
    },
    {
      head: <span className="sub-text">{"User Report"}</span>,
      cell: (row) => <span>{row.createdBy?.fullName}</span>,
    },
    {
      head: <span className="sub-text">{"Created at"}</span>,
      cell: (row) => (
        <span>{moment(row.createdAt).format("DD/MM/yyyy hh:mm:ss")}</span>
      ),
    },
    {
      head: <span className="sub-text"></span>,
      cellClassName: "nk-tb-col-tools",
      cell: (row) => (
        <ul
          className="nk-tb-actions gx-1"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#delete"
                      className="link link-danger"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onDeleteClick(row);
                      }}
                    >
                      <Icon name="delete"></Icon>
                      <span>{"Delete"}</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      ),
    },
  ];
}
const ReportsPage = () => {
  const [smOption, setSmOption] = useState(false);
  const { list, summary, loading } = useSelector((state) => state.reports);

  const [params, setParams] = useState(defaultParams);
  const dispatch = useDispatch();
  const _handleInputChange = (value) => {};
  const handleInputChange = debounce(_handleInputChange, 500);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState({
    show: false,
  });
  const openModal = (report) => {
    setIsDeleteModalVisible({ report, show: true });
  };
  const closeModal = () => {
    setIsDeleteModalVisible({
      report: null,
      show: false,
    });
  };
  useEffect(() => {
    dispatch(getListReport(params));
  }, [dispatch, params]);
  const handlePageChange = (page) => {
    setParams({
      ...params,
      page,
    });
  };
  const onDeleteReport = async () => {
    try {
      await deleteReport(isDeleteModalVisible.report?._id);
      dispatch(deleteReportSuccess(isDeleteModalVisible.report));
      toast.success("Delete report successfully");
    } catch (error) {
      toast.error(error);
    }
  };
  const dataTableColumns = getDefaultColumns(openModal);
  return (
    <React.Fragment>
      <Head title="Reports"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Reports</BlockTitle>
            </BlockHeadContent>
            {/* <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmOption(!smOption);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div
                  className="toggle-expand-content"
                  style={{ display: smOption ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="Search by name"
                          onChange={(e) => handleInputChange(e.target.value)}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent> */}
          </BlockBetween>
        </BlockHead>
        <BasePaginationTable
          list={list}
          loading={loading}
          columns={dataTableColumns}
          onPageChange={handlePageChange}
          hasNextPage={summary?.hasNextPage}
          totalItems={summary?.count}
          {...params}
        />
      </Content>
      <DeleteConfirmationModal
        onConfirm={onDeleteReport}
        isOpen={isDeleteModalVisible.show}
        onCloseModal={closeModal}
      />
    </React.Fragment>
  );
};

export default ReportsPage;
