import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Dashboard from "../pages/dashboard/Dashboard";
import Consultants from "../pages/mi-services/Consultants";

import Blank from "../pages/others/Blank";
import Faq from "../pages/others/Faq";
import Regularv1 from "../pages/others/Regular-1";
import Regularv2 from "../pages/others/Regular-2";
import Terms from "../pages/others/Terms";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Success from "../pages/auth/Success";

import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import Coaches from "../pages/mi-services/Coaches";
import RolesPage from "../pages/admin/Roles";
import AdminsPage from "../pages/admin/SubAdmin";
import CategoriesPage from "../pages/admin/Categories";
import EventsPage from "../pages/event/Events";
import ForumsPage from "../pages/forum/Forums";
import ProductsPage from "../pages/product/Products";
import UsersPage from "../pages/user/Users";
import PostsPage from "../pages/social/Posts";
import ReportsPage from "../pages/report/Reports";
import RolePermissionsPage from "../pages/admin/RolePermissions";
import EditEventPage from "../pages/event/EditEvent";
import ProductDetailsPage from "../pages/product/ProductDetail";
import EditUserPage from "../pages/user/EditUser";
import ChangePassword from "../pages/auth/ChangePassword";
import PostDetailsPage from "../pages/social/PostDetail";

const Router = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<Dashboard />}></Route>
        <Route path="coaches" element={<Coaches />}></Route>
        <Route
          path="coaches/:id"
          element={<EditUserPage type={"mentor"} />}
        ></Route>
        <Route path="consultants" element={<Consultants />}></Route>
        <Route
          path="consultants/:id"
          element={<EditUserPage type={"trainer"} />}
        ></Route>
        <Route path="roles" element={<RolesPage />}></Route>
        <Route
          path="role-permissions"
          element={<RolePermissionsPage />}
        ></Route>
        <Route path="change-password" element={<ChangePassword />}></Route>
        <Route path="administrators" element={<AdminsPage />}></Route>
        <Route path="categories" element={<CategoriesPage />}></Route>
        <Route path="events" element={<EventsPage />}></Route>
        <Route path="events/:id" element={<EditEventPage />}></Route>
        <Route path="events/add" element={<EditEventPage />}></Route>
        <Route path="forums" element={<ForumsPage />}></Route>
        <Route path="products" element={<ProductsPage />}></Route>
        <Route path="products/:id" element={<ProductDetailsPage />}></Route>
        <Route path="users" element={<UsersPage />}></Route>
        <Route path="users/:id" element={<EditUserPage />}></Route>
        <Route path="posts" element={<PostsPage />}></Route>
        <Route path="posts/:id" element={<PostDetailsPage />}></Route>
        <Route path="reports" element={<ReportsPage />}></Route>
        <Route path="_blank" element={<Blank />}></Route>

        <Route path="pages">
          <Route path="terms-policy" element={<Terms />}></Route>
          <Route path="faq" element={<Faq />}></Route>
          <Route path="regular-v1" element={<Regularv1 />}></Route>
          <Route path="regular-v2" element={<Regularv2 />}></Route>
        </Route>
      </Route>

      <Route element={<LayoutNoSidebar />}>
        <Route path="auth-success" element={<Success />}></Route>
        <Route path="auth-reset" element={<ForgotPassword />}></Route>
        <Route path="auth-register" element={<Register />}></Route>
        <Route path="login" element={<Login />}></Route>

        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};
export default Router;
