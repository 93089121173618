import axios from "axios";
import { requestError } from "../utils/RequestError";


export const getDashboard = async (params) => {
  try {
    const res = await axios.get(`/admin/dashboard`, { params });
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};

