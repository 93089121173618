import { createSlice } from "@reduxjs/toolkit";
import * as UserAPI from "../api/user";
const coaches = createSlice({
  name: "coaches",
  initialState: {
    loading: false,
    error: null,
    list: [],
    summary: null,
  },
  reducers: {
    setLoading: (state) => {
      state.error = null;
      state.loading = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    getListSuccess: (state, action) => {
      state.list = action.payload.docs;
      state.summary = action.payload.summary;
      state.loading = false;
    },
    updateCoachSuccess: (state, action) => {
      state.list = state.list.map((coach) => {
        if (coach._id === action.payload._id) {
          return action.payload;
        } else {
          return coach;
        }
      });
    },
    deleteCoachSuccess: (state, action) => {
      state.list = state.list.filter(
        (item) => action.payload?._id !== item._id
      );
    },
  },
});

export const getListCoach = (params) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await UserAPI.getListUser({
      ...params,
      roles: "service_provider",
      serviceTypes: "mentor",
    });
    dispatch(getListSuccess(res));
  } catch (error) {
    dispatch(setError(error));
  }
};

const { reducer, actions } = coaches;
export const {
  setLoading,
  setError,
  getListSuccess,
  deleteCoachSuccess,
  updateCoachSuccess,
} = actions;
export default reducer;
