import React, { useEffect } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import DataCard from "../../components/partials/default/DataCard";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
} from "../../components/Component";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getDashboardDetail } from "../../slice/dashboard";

const Dashboard = () => {
  const { data } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDashboardDetail());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Head title="Dashboard"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockHeadContent>
            <BlockTitle page tag="h3">
              Dashboard
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>
        <Block>
          {data&& 
          <Row className="g-gs">
            <Col xxl="3" sm="6">
              <DataCard title="Total Users" amount={data.totalUsers} />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard title="Dormant Users" amount={data.dormantUsers} />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard title="Paid Users" amount={data.paidUsers} />
            </Col>
            <Col xxl="3" sm="6">
              <DataCard title="New Users" amount={data.newUsers} />
            </Col>
          </Row>}
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Dashboard;
