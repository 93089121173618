import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import auth from "../slice/auth";
import categories from "../slice/categories";
import forums from "../slice/forums";
import users from "../slice/users";
import events from "../slice/events";
import coaches from "../slice/coaches";
import trainers from "../slice/trainers";
import userDetail from "../slice/user-detail";
import productDetail from "../slice/product-detail";
import dashboard from "../slice/dashboard";
import reports from "../slice/reports";
import products from "../slice/products";
import postDetail from "../slice/post-detail";
import posts from "../slice/posts";

import { onAuthSuccess, checkAuthOnRehydrate } from "./middleware";
import { createFilter } from "redux-persist-transform-filter";

const persistConfig = {
  key: "root",
  storage,
  transforms: [
    createFilter("auth", ["accessToken", "userInfo"]),
    createFilter("categories", ["list"]),
    createFilter("forums", ["list"]),
  ],
};

const rootReducer = combineReducers({
  auth,
  categories,
  forums,
  events,
  coaches,
  trainers,
  userDetail,
  dashboard,
  users,
  reports,
  products,
  productDetail,
  posts,
  postDetail,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: [],
      },
    }).concat([onAuthSuccess, checkAuthOnRehydrate]);
  },
});

export const persistor = persistStore(store);

export default store;
