import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import {
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  Icon,
} from "../../components/Component";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import BasePaginationTable from "../../components/table/BasePaginationTable";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { deletePostSuccess, getListPost } from "../../slice/posts";
import { defaultParams } from "../../constants";
import { deletePost } from "../../api/post";
import { toast } from "react-toastify";
import DeleteConfirmationModal from "../admin/modals/DeleteConfimationModal";
import { getDetailSuccess } from "../../slice/post-detail";

function getDefaultColumns(onViewClick, onDeleteClick) {
  return [
    {
      head: <span className="sub-text">{"Title"}</span>,
      cell: (row) => <span>{row.title}</span>,
    },
    {
      head: <span className="sub-text">{"Assets"}</span>,
      cell: (row) => {
        var asset = row.assets.length > 0 ? row.assets[0] : null;

        return (
          <>
            {asset &&
              (asset.type === "video" ? (
                <video
                  src={asset.link}
                  alt=""
                  autoPlay={false}
                  width={100}
                  style={{
                    objectFit: "cover",
                  }}
                />
              ) : (
                <img
                  src={asset.link}
                  alt="product-img"
                  width={100}
                  style={{
                    objectFit: "cover",
                  }}
                />
              ))}
          </>
        );
      },
    },
    {
      head: <span className="sub-text">{"Type"}</span>,
      cell: (row) => <span>{row.type}</span>,
    },
    {
      head: <span className="sub-text">{"Created By"}</span>,
      cell: (row) => <span>{row.createdBy?.fullName ?? "No name"}</span>,
    },
    {
      head: <span className="sub-text">{"Total Comments"}</span>,
      cell: (row) => <span>{row.totalComments}</span>,
    },
    {
      head: <span className="sub-text">{"Total Liked"}</span>,
      cell: (row) => <span>{row.liked?.length}</span>,
    },
    {
      head: <span className="sub-text">{"Created at"}</span>,
      cell: (row) => (
        <span>{moment(row.createdAt).format("DD/MM/yyyy hh:mm:ss")}</span>
      ),
    },
    {
      head: <span className="sub-text"></span>,
      cellClassName: "nk-tb-col-tools",
      cell: (row) => (
        <ul
          className="nk-tb-actions gx-1"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#view"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onViewClick(row);
                      }}
                    >
                      <Icon name="eye"></Icon>
                      <span>{"View"}</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#delete"
                      className="link link-danger"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onDeleteClick(row);
                      }}
                    >
                      <Icon name="delete"></Icon>
                      <span>{"Delete"}</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      ),
    },
  ];
}
const PostsPage = () => {
  const { list, summary, loading } = useSelector((state) => state.posts);
  const [smOption, setSmOption] = useState(false);
  const [params, setParams] = useState(defaultParams);
  const dispatch = useDispatch();
  const [isPostModalVisible, setPostModalVisible] = useState({
    post: null,
    delete: false,
  });
  const navigate = useNavigate();
  const goToDetail = (post) => {
    dispatch(getDetailSuccess(post));
    navigate(`/posts/${post._id}`);
  };
  const closeModal = () => {
    setPostModalVisible({
      post: null,
      delete: false,
    });
  };
  const onDelete = (post) => {
    setPostModalVisible({ post, delete: true });
  };
  useEffect(() => {
    dispatch(getListPost(params));
  }, [dispatch, params]);
  const dataTableColumns = getDefaultColumns(goToDetail, onDelete);

  const _handleInputChange = (value) => {
    setParams((prev) => ({
      ...prev,
      keyword: value,
    }));
  };
  const handleInputChange = debounce(_handleInputChange, 500);
  const handlePageChange = (page) => {
    setParams({
      ...params,
      page,
    });
  };
  const onDeletePost = async () => {
    try {
      await deletePost(isPostModalVisible.post?._id);
      dispatch(deletePostSuccess(isPostModalVisible.post));
      toast.success("Delete post successfully");
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <React.Fragment>
      <Head title="Posts"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Posts</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmOption(!smOption);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div
                  className="toggle-expand-content"
                  style={{ display: smOption ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="Search by name"
                          onChange={(e) => handleInputChange(e.target.value)}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <BasePaginationTable
          list={list}
          loading={loading}
          columns={dataTableColumns}
          onPageChange={handlePageChange}
          onRowClick={goToDetail}
          hasNextPage={summary?.hasNextPage}
          totalItems={summary?.count}
          {...params}
        />
      </Content>
      <DeleteConfirmationModal
        onConfirm={onDeletePost}
        isOpen={isPostModalVisible.delete}
        onCloseModal={closeModal}
      />
    </React.Fragment>
  );
};

export default PostsPage;
