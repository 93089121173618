import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  BlockHead,
  BlockTitle,
  BlockHeadContent,
  Icon,
} from "../../components/Component";
import { Button, Spinner } from "reactstrap";
import BasePaginationTable from "../../components/table/BasePaginationTable";
import DeleteConfirmationModal from "../admin/modals/DeleteConfimationModal";
import { defaultParams } from "../../constants";
import { useDispatch } from "react-redux";
import {
  deleteProductSuccess,
  getListProduct,
  updateProductSuccess,
} from "../../slice/products";
import { deleteProduct, updateProduct } from "../../api/product";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getDetailSuccess } from "../../slice/product-detail";

function getDefaultColumns(
  onEditClick,
  onDeleteClick,
  onActive,
  isActiveLoading
) {
  return [
    {
      head: <span className="sub-text">{"Name"}</span>,
      cell: (row) => <span>{row.name}</span>,
    },
    {
      head: <span className="sub-text">{"Description"}</span>,
      cell: (row) => <span>{row.desc}</span>,
    },

    {
      head: <span className="sub-text">{"Image"}</span>,
      cell: (row) => (
        <img
          src={row.assets.length > 0 ? row.assets[0].link : null}
          alt="product-img"
          width={100}
          style={{
            objectFit: "cover",
          }}
        />
      ),
    },
    {
      head: <span className="sub-text">{"Quantity"}</span>,
      cell: (row) => <span>{row.quantity}</span>,
    },
    {
      head: <span className="sub-text">{"Weight"}</span>,
      cell: (row) => <span>{row.weight}</span>,
    },
    {
      head: <span className="sub-text">{"Price"}</span>,
      cell: (row) => <span>{row.price}</span>,
    },
    {
      head: <span className="sub-text">{"Category"}</span>,
      cell: (row) => <span>{row.category?.name}</span>,
    },
    {
      head: <span className="sub-text">{"Status"}</span>,
      cell: (row) => (
        <Button
          color={row.enabled ? "danger" : "success"}
          className="w-min-175px center"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onActive(row);
          }}
        >
          {isActiveLoading === row._id ? (
            <Spinner size="sm" color="light" />
          ) : row.enabled ? (
            "Deactive/Unactive"
          ) : (
            "Active/Actived"
          )}
        </Button>
      ),
    },
    {
      head: <span className="sub-text"></span>,
      cell: (row) => (
        <a
          href="#edit"
          className="link link-light"
          onClick={(ev) => {
            ev.preventDefault();
            onEditClick(row);
          }}
        >
          <Icon name="eye"></Icon>
          <span>{"View"}</span>
        </a>
      ),
    },
  ];
}
const ProductsPage = () => {
  const { list, summary, loading } = useSelector((state) => state.products);
  const [isActiveLoading, setActiveLoading] = useState("-1");
  const navigate = useNavigate();
  const [params, setParams] = useState(defaultParams);
  const dispatch = useDispatch();
  const [isProductModalVisible, setProductModalVisible] = useState({
    product: null,
    delete: false,
  });
  const onEdit = (product) => {
    dispatch(getDetailSuccess(product));
    navigate(`/products/${product?._id}`);
  };
  const closeModal = () => {
    setProductModalVisible({
      product: null,
      delete: false,
    });
  };
  const onDelete = (product) => {
    setProductModalVisible({ product, delete: true });
  };
  useEffect(() => {
    dispatch(getListProduct(params));
  }, [dispatch, params]);
  const handlePageChange = (page) => {
    setParams({
      ...params,
      page,
    });
  };
  /// func
  const onDeleteProduct = async () => {
    try {
      await deleteProduct(isProductModalVisible.product?._id);
      dispatch(deleteProductSuccess(isProductModalVisible.product));
      toast.success("Delete product successfully");
    } catch (error) {
      toast.error(error);
    }
  };
  const activeProduct = async (product) => {
    setActiveLoading(product?._id);
    try {
      const res = await updateProduct(product?._id, {
        active: !product?.enabled,
      });
      toast.success("Update product successfully");
      dispatch(updateProductSuccess(res));
    } catch (error) {
      toast.error(error);
    }
    setActiveLoading("-1");
  };
  const dataTableColumns = getDefaultColumns(
    onEdit,
    onDelete,
    activeProduct,
    isActiveLoading
  );
  return (
    <React.Fragment>
      <Head title="Products"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockHeadContent>
            <BlockTitle>Products</BlockTitle>
          </BlockHeadContent>
        </BlockHead>
        <BasePaginationTable
          list={list}
          loading={loading}
          columns={dataTableColumns}
          onPageChange={handlePageChange}
          onRowClick={onEdit}
          hasNextPage={summary?.hasNextPage}
          totalItems={summary?.count}
          {...params}
        />
      </Content>
      <DeleteConfirmationModal
        onConfirm={onDeleteProduct}
        isOpen={isProductModalVisible.delete}
        onCloseModal={closeModal}
      />
    </React.Fragment>
  );
};

export default ProductsPage;
