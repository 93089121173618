import React, { useEffect } from "react";
import { Modal, ModalBody, Form, Label, Spinner } from "reactstrap";
import { Icon, Col, Button } from "../../../components/Component";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { membershipTypes, serviceTypes } from "../../../constants";
import { updateUser } from "../../../api/user";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateUserSuccess } from "../../../slice/users";

const UpdateUserModal = ({ isOpen, closeModal, user }) => {
  const schema = yup
    .object({
      fullName: yup.string().required("Name is required"),
      email: yup
        .string()
        .required("Email is required")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          "Invalid email address"
        ),
      // password: yup
      //   .string()
      //   .required("Password is required")
      //   .min(6, "Password must be at least 6 characters"),
      // confirmPassword: yup
      //   .string()
      //   .oneOf([yup.ref("password"), null], "Password must match"),
      role: yup.string().required("Membership type is required"),
      serviceType: yup.string().when(["role"], (role, schema) => {
        return role === "service_provider"
          ? schema.required("Service type is required")
          : schema;
      }),
    })
    .required();
  const {
    watch,
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const role = watch("role");
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    try {
      const res = await updateUser(user?._id, {
        ...data,
        socials: user?.socials,
      });
      toast.success("Update user successfully");
      dispatch(updateUserSuccess(res));
      closeModal();
    } catch (error) {
      toast.error(error);
    }
  };
  useEffect(() => {
    reset({
      fullName: user?.fullName,
      role: user?.role,
      email: user?.email,
      serviceType: user?.serviceType,
    });
  }, [reset, user]);
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeModal()}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{user != null ? "Update" : "Add"} User</h5>
          <div className="mt-4">
            <Form
              className="row gy-4 form-validate is-alter"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      {...register("fullName")}
                      placeholder="Enter name"
                    />
                    {errors.fullName && (
                      <span className="invalid">{errors.fullName.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-email">
                    Email
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      id="fv-email"
                      className="form-control"
                      readOnly
                      type="text"
                      {...register("email")}
                      placeholder="Enter email address"
                    />
                    {errors.email && (
                      <span className="invalid">{errors.email.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              {/* <Col md="6">
                <div className="form-group">
                  <label className="form-label">Password</label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="password"
                      {...register("password")}
                      placeholder="Enter password"
                    />
                    {errors.password && (
                      <span className="invalid">{errors.password.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Confirm Password</label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      {...register("confirmPassword")}
                      placeholder="Enter confirm password"
                    />
                    {errors.confirmPassword && (
                      <span className="invalid">
                        {errors.confirmPassword.message}
                      </span>
                    )}
                  </div>
                </div>
              </Col> */}
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Menbership Type</label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <select
                        className="form-control form-select"
                        {...register("role")}
                        placeholder="Select a option"
                      >
                        {membershipTypes.map((role, i) => (
                          <option key={i} label={role.label} value={role.value}>
                            {role.label}
                          </option>
                        ))}
                      </select>
                      {errors.role && (
                        <span className="invalid">{errors.role.message}</span>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              {role === "service_provider" && (
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Service Type</label>
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <select
                          className="form-control form-select"
                          {...register("serviceType")}
                          placeholder="Select a option"
                        >
                          {serviceTypes.map((type, i) => (
                            <option
                              key={i}
                              label={type.label}
                              value={type.value}
                            >
                              {type.label}
                            </option>
                          ))}
                        </select>
                        {errors.serviceType && (
                          <span className="invalid">
                            {errors.serviceType.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              )}

              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {isSubmitting ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                  <li className="flex-grow-1">
                    <div className="float-end">
                      <Button
                        color="success"
                        size="md"
                        type="button"
                        onClick={() => {}}
                      >
                        Create as Admin Account
                      </Button>
                    </div>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default UpdateUserModal;
