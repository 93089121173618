import axios from "axios";
import {getCurrentUrl} from '../utils/Utils'

const authActions = ["auth/loginSuccess"];

export const onAuthSuccess = () => (next) => (action) => {
  if (authActions.includes(action.type)) {
    const { accessToken } = action.payload;
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else if (action.type === "LOGIN_FAILURE") {
    delete axios.defaults.headers.Authorization;
  }
  axios.defaults.headers.common.domain = getCurrentUrl();
  return next(action);
};

export const checkAuthOnRehydrate = () => (next) => (action) => {
  if (action.type === "persist/REHYDRATE") {
    if (
      action.payload &&
      action.payload.auth &&
      action.payload.auth.accessToken
    ) {
      const { accessToken } = action.payload.auth;
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      delete axios.defaults.headers.Authorization;
    }
  }
  return next(action);
};
