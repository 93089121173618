import { createSlice } from "@reduxjs/toolkit";
import * as ReportApi from "../api/report";

const reports = createSlice({
  name: "reports",
  initialState: {
    loading: false,
    error: null,
    list: [],
    summary: null,
  },
  reducers: {
    setLoading: (state) => {
      state.error = null;
      state.loading = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    getListSuccess: (state, action) => {
      state.summary = action.payload.summary;
      state.list = action.payload.docs;
      state.loading = false;
    },
    updateReportSuccess: (state, action) => {
      state.list = state.list.map((report) => {
        if (report._id === action.payload._id) {
          return action.payload;
        } else {
          return report;
        }
      });
    },
    deleteReportSuccess: (state, action) => {
      state.list = state.list.filter(
        (item) => action.payload?._id !== item._id
      );
    },
  },
});

export const getListReport = (params) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await ReportApi.getListReport(params);
    dispatch(getListSuccess(res));
  } catch (error) {
    dispatch(setError(error));
  }
};

const { reducer, actions } = reports;
export const {
  setLoading,
  setError,
  getListSuccess,
  updateReportSuccess,
  deleteReportSuccess,
} = actions;
export default reducer;
