import axios from "axios";
import { requestError } from "../utils/RequestError";

export const addForum = async (payload) => {
  try {
    const res = await axios.post(`/admin/forums`, payload);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const getListForum = async (params) => {
  try {
    const res = await axios.get(`/forums`, { params });
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};

export const updateForum = async (id, payload) => {
  try {
    const res = await axios.put(`/admin/forums/${id}`, payload);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const deleteForum = async (id) => {
  try {
    const res = await axios.delete(`/admin/forums/${id}`);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const reorderForum = async (payload) => {
  try {
    const res = await axios.patch(`/admin/forums/reorder`, payload);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
