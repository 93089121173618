import axios from "axios";
import { requestError } from "../utils/RequestError";

export const getListReport = async (params) => {
  try {
    const res = await axios.get(`/admin/reports`, { params });
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const getReportById = async (id) => {
  try {
    const res = await axios.get(`/admin/reports/${id}`);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};

export const updateReport = async (id, payload) => {
  try {
    const res = await axios.put(`/admin/reports/${id}`, payload);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const approveReport = async (id,payload) => {
  try {
    const res = await axios.patch(`/admin/reports/${id}/active`, payload);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const deleteReport = async (id) => {
  try {
    const res = await axios.delete(`/admin/reports/${id}`);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
