import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { useNavigate } from "react-router-dom";
import {
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  Icon,
} from "../../components/Component";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
} from "reactstrap";
import BasePaginationTable from "../../components/table/BasePaginationTable";
import moment from "moment";
import { useSelector } from "react-redux";
import { defaultParams } from "../../constants";
import { useDispatch } from "react-redux";
import { deleteEventSuccess, getListEvent } from "../../slice/events";
import { deleteEvent } from "../../api/event";
import { toast } from "react-toastify";
import DeleteConfirmationModal from "../admin/modals/DeleteConfimationModal";
import UpdateEventModal from "./modals/UpdateEventModal";
function getDefaultColumns(onEditClick, onDeleteClick) {
  return [
    {
      head: <span className="sub-text">{"Title"}</span>,
      cell: (row) => <span>{row.name}</span>,
    },
    {
      head: <span className="sub-text">{"Description"}</span>,
      cell: (row) => <span>{row.desc}</span>,
    },

    {
      head: <span className="sub-text">{"Image"}</span>,
      cell: (row) => (
        <img
          src={row.image}
          alt="event-img"
          width={100}
          style={{
            objectFit: "cover",
          }}
        />
      ),
    },
    {
      head: <span className="sub-text">{"Location"}</span>,
      cell: (row) => <span>{row.location}</span>,
    },
    {
      head: <span className="sub-text">{"From"}</span>,
      cell: (row) => <span>{moment(row.from).format("DD/MM/yyyy hh:mm")}</span>,
    },
    {
      head: <span className="sub-text">{"To"}</span>,
      cell: (row) => <span>{moment(row.to).format("DD/MM/yyyy hh:mm")}</span>,
    },
    {
      head: <span className="sub-text"></span>,
      cellClassName: "nk-tb-col-tools",
      cell: (row) => (
        <ul
          className="nk-tb-actions gx-1"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onEditClick(row);
                      }}
                    >
                      <Icon name="eye"></Icon>
                      <span>{"Edit"}</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      className="link link-danger"
                      href="#delete"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onDeleteClick(row);
                      }}
                    >
                      <Icon name="delete"></Icon>
                      <span>{"Delete"}</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      ),
    },
  ];
}
const EventsPage = () => {
  const [smOption, setSmOption] = useState(false);
  const onDelete = (event) => {
    setEventModalVisible({ event, delete: true });
  };
  const closeModal = () => {
    setEventModalVisible({
      event: null,
      delete: false,
    });
  };
  const openModal = (event) => {
    setEventModalVisible({ event, update: true });
  };
  const [params, setParams] = useState(defaultParams);
  const dispatch = useDispatch();
  const [isEventModalVisible, setEventModalVisible] = useState({
    event: null,
    delete: false,
    update: false,
  });
  const dataTableColumns = getDefaultColumns(openModal, onDelete);
  const { list, summary, loading } = useSelector((state) => state.events);
  useEffect(() => {
    dispatch(getListEvent(params));
  }, [dispatch, params]);
  const handlePageChange = (page) => {
    setParams({
      ...params,
      page,
    });
  };
  /// func
  const onDeleteEvent = async () => {
    try {
      await deleteEvent(isEventModalVisible.event?._id);
      dispatch(deleteEventSuccess(isEventModalVisible.event));
      toast.success("Delete event successfully");
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <React.Fragment>
      <Head title="Events"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Events</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmOption(!smOption);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div
                  className="toggle-expand-content"
                  style={{ display: smOption ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        color="primary"
                        onClick={() => {
                          openModal();
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button>
                      <Button
                        className="toggle d-none d-md-inline-flex"
                        color="primary"
                        onClick={() => {
                          openModal();
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Event</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <BasePaginationTable
          list={list}
          loading={loading}
          columns={dataTableColumns}
          onPageChange={handlePageChange}
          hasNextPage={summary?.hasNextPage}
          totalItems={summary?.count}
          {...params}
        />
      </Content>
      <UpdateEventModal
        event={isEventModalVisible.event}
        isOpen={isEventModalVisible.update}
        closeModal={closeModal}
      />
      <DeleteConfirmationModal
        onConfirm={onDeleteEvent}
        isOpen={isEventModalVisible.delete}
        onCloseModal={closeModal}
      />
    </React.Fragment>
  );
};

export default EventsPage;
