import axios from "axios";
import { requestError } from "../utils/RequestError";

export const addCategory = async (payload) => {
  try {
    const res = await axios.post(`/admin/categories`, payload);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const getListCategory = async (params) => {
  try {
    const res = await axios.get(`/admin/categories`, { params });
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const getCategories = async (params) => {
  try {
    const res = await axios.get(`/categories`, { params });
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};

export const updateCategory = async (id, payload) => {
  try {
    const res = await axios.put(`/admin/categories/${id}`, payload);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const deleteCategory = async (id) => {
  try {
    const res = await axios.delete(`/admin/categories/${id}`);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
