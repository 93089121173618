import { createSlice } from "@reduxjs/toolkit";

const auth = createSlice({
  name: "auth",
  initialState: {
    accessToken: null,
    userInfo: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.userInfo = action.payload.userInfo;
    },
    logout: (state) => {
      state.accessToken = null;
      state.userInfo = null;
    },
  },
});

const { reducer, actions } = auth;
export const { loginSuccess, logout } = actions;
export default reducer;
