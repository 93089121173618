import axios from "axios";
import { requestError } from "../utils/RequestError";

export const getListPost = async (params) => {
  try {
    const res = await axios.get(`/admin/posts`, { params });
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const getPostById = async (id) => {
  try {
    const res = await axios.get(`/admin/posts/${id}`);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};

export const deletePost = async (id) => {
  try {
    const res = await axios.delete(`/admin/posts/${id}`);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
