import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  Icon,
  RSelect,
} from "../../components/Component";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
} from "reactstrap";
import BasePaginationTable from "../../components/table/BasePaginationTable";
import UpdateCategoryModal from "./modals/UpdateCategoryModal";
import DeleteConfirmationModal from "./modals/DeleteConfimationModal";
import { useSelector } from "react-redux";
import { deleteCategory } from "../../api/category";
import { toast } from "react-toastify";
import { deleteCategorySuccess, getListCategory } from "../../slice/categories";
import { useDispatch } from "react-redux";
import { categoryTypes, defaultParams } from "../../constants";

function getDefaultColumns(onEditClick, onDeleteClick) {
  return [
    {
      head: <span className="sub-text">{"Name"}</span>,
      cell: (row) => <span>{row.name}</span>,
    },
    {
      head: <span className="sub-text">{"Type"}</span>,
      cell: (row) => <span>{row.type}</span>,
    },
    {
      head: <span className="sub-text"></span>,
      cellClassName: "nk-tb-col-tools",
      cell: (row) => (
        <ul
          className="nk-tb-actions gx-1"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onEditClick(row);
                      }}
                    >
                      <Icon name="eye"></Icon>
                      <span>{"Edit"}</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      className="link link-danger"
                      href="#delete"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onDeleteClick(row);
                      }}
                    >
                      <Icon name="delete"></Icon>
                      <span>{"Delete"}</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      ),
    },
  ];
}
const CategoriesPage = () => {
  const { list, summary, loading } = useSelector((state) => state.categories);
  const [params, setParams] = useState(defaultParams);
  const dispatch = useDispatch();

  const [smOption, setSmOption] = useState(false);

  const [isCategoryModalVisible, setCategoryModalVisible] = useState({
    category: null,
    update: false,
    delete: false,
  });
  const onDeleteCategory = async () => {
    try {
      await deleteCategory(isCategoryModalVisible.category?._id);
      dispatch(deleteCategorySuccess(isCategoryModalVisible.category));
      toast.success("Delete category successfully");
    } catch (error) {
      toast.error(error);
    }
  };
  const openModal = (category) => {
    setCategoryModalVisible({ category, update: true });
  };
  const closeModal = () => {
    setCategoryModalVisible({
      category: null,
      delete: false,
      update: false,
    });
  };
  const onDelete = (category) => {
    setCategoryModalVisible({ category, delete: true });
  };
  const dataTableColumns = getDefaultColumns(openModal, onDelete);
  useEffect(() => {
    dispatch(getListCategory(params));
  }, [dispatch, params]);
  const handlePageChange = (page) => {
    setParams({
      ...params,
      page,
    });
  };
  return (
    <React.Fragment>
      <Head title="Categories"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Categories</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmOption(!smOption);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div
                  className="toggle-expand-content"
                  style={{ display: smOption ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li className="w-350px">
                      <div className="w-100">
                        <RSelect
                          options={categoryTypes}
                          isMulti
                          onChange={(val) => {
                            setParams({
                              ...params,
                              page: 1,
                              types: val.map((e) => e.value),
                            });
                          }}
                          placeholder="Any Type"
                        />
                      </div>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        color="primary"
                        onClick={() => {
                          openModal();
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button>
                      <Button
                        className="toggle d-none d-md-inline-flex"
                        color="primary"
                        onClick={() => {
                          openModal();
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Category</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <BasePaginationTable
          list={list}
          loading={loading}
          columns={dataTableColumns}
          onPageChange={handlePageChange}
          hasNextPage={summary?.hasNextPage}
          totalItems={summary?.count}
          {...params}
        />
      </Content>
      <UpdateCategoryModal
        category={isCategoryModalVisible.category}
        isOpen={isCategoryModalVisible.update}
        closeModal={closeModal}
      />
      <DeleteConfirmationModal
        onConfirm={onDeleteCategory}
        isOpen={isCategoryModalVisible.delete}
        onCloseModal={closeModal}
      />
    </React.Fragment>
  );
};

export default CategoriesPage;
