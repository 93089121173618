import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { uploadFile } from "../../../api/upload-file";
import { addEvent, updateEvent } from "../../../api/event";
import { addEventSuccess, updateEventSuccess } from "../../../slice/events";
import { toast } from "react-toastify";

const useEventForm = (event, { closeModal }) => {
  console.log(event);
  const schema = yup
    .object({
      name: yup.string().required("Name is required"),
      desc: yup.string().required("Description is required"),
      image: yup.string().required("Image is required"),
      from: yup.string().required(),
      to: yup.string().required(),
      location: yup.string().required(),
      link: yup.string(),
    })
    .required();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...event,
    },
  });
  const { watch, setValue, reset } = form;
  const from = watch("from");
  const to = watch("to");

  const onFormSubmit = async (data) => {
    if (event) {
      await onUpdateEvent(data);
    } else {
      await onAddEvent(data);
    }
  };
  const image = watch("image");
  useEffect(() => {
    reset({
      ...event,
    });
  }, [reset, event]);
  ///
  const dispatch = useDispatch();
  const [isLoadingImage, setLoadingImage] = useState(false);
  const uploadImage = async (payload) => {
    setLoadingImage(true);
    try {
      const res = await uploadFile(payload);
      setValue("image", res.fileUrl);
    } catch (error) {
      toast.error(error);
    }
    setLoadingImage(false);
  };
  const onAddEvent = async (data) => {
    try {
      const res = await addEvent(data);
      dispatch(addEventSuccess(res));
      toast.success("Add event successfully");
      closeModal();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const onUpdateEvent = async (data) => {
    try {
      const res = await updateEvent(event._id, data);
      dispatch(updateEventSuccess(res));
      toast.success("Update event successfully");
      closeModal();
    } catch (error) {
      toast.error(error?.message);
    }
  };
  return { form, onFormSubmit, from, to, isLoadingImage, image, uploadImage };
};

export default useEventForm;
