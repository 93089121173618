import React, { useState } from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import { Button, Icon } from "../../../components/Component";

const DeleteConfirmationModal = ({
  title,
  desc,
  isOpen,
  onCloseModal,
  onConfirm,
}) => {
  const [isLoading, setLoading] = useState(false);
  const onConfirmation = async () => {
    setLoading(true);
    await onConfirm();
    onCloseModal();
    setLoading(false);
  };
  return (
    <Modal isOpen={isOpen} toggle={onCloseModal}>
      <ModalBody className="modal-body-lg text-center">
        <div className="nk-modal">
          <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></Icon>
          <h4 className="nk-modal-title">{title ?? "Are you sure?"}</h4>
          <div className="nk-modal-text">
            <p className="lead">
              {desc ??
                "This record and it`s details will be permanantly deleted!"}
            </p>
            {/* <p className="text-soft">
              If you need help please contact us at (855) 485-7373.
            </p> */}
          </div>
          <div className="nk-modal-action mt-5">
            <Button color="light" className="btn-mw" onClick={onCloseModal}>
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              color="danger"
              className="btn-mw ms-3"
              onClick={onConfirmation}
            >
              {isLoading ? <Spinner size="sm" color="light" /> : "Confirm"}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default DeleteConfirmationModal;
