import React, { useEffect, useRef, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
  Icon,
  Block,
} from "../../components/Component";
import { Card } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getProductDetail } from "../../slice/product-detail";
import Slider from "react-slick";

const sliderSettingsDefault = {
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  slide: null,
  responsive: [
    { breakpoint: 1539, settings: { slidesToShow: 3 } },
    { breakpoint: 768, settings: { slidesToShow: 2 } },
    { breakpoint: 420, settings: { slidesToShow: 1 } },
  ],
  arrows: false,
  swipeToSlide: true,
  focusOnSelect: true,
  className: "slider-init slider-nav",
};
const ProductDetailsPage = ({ match }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProductDetail(id));
  }, [id, dispatch]);
  const { product } = useSelector((state) => state.productDetail);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);
  const slideChange = (index) => {
    setCurrentSlide(index);
  };
  return (
    <React.Fragment>
      <Head title="Product Detail"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="g-3">
            <BlockHeadContent>
              <BlockTitle>Product Details</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Link to={`/products`}>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
              </Link>
              <Link to={`/products`}>
                <Button
                  color="light"
                  outline
                  className="btn-icon bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </Button>
              </Link>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        {product && (
          <Block>
            <Card>
              <div className="card-inner">
                <Row className="pb-5">
                  <Col lg={6}>
                    <div className="product-gallery me-xl-1 me-xxl-5">
                      {product.assets && product.assets.length > 0 && (
                        <>
                          <Slider
                            asNavFor={nav2}
                            ref={slider1}
                            arrows={false}
                            fade={true}
                            slidesToShow={1}
                            slidesToScroll={1}
                            initialSlide={currentSlide}
                            className="slider-init"
                            prevArrow
                          >
                            <div
                              className="slider-item rounded"
                              key={currentSlide}
                            >
                              <img
                                src={product.assets[currentSlide].link}
                                className="w-100"
                                alt=""
                                style={{
                                  height: "568px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          </Slider>
                          <Slider
                            asNavFor={nav1}
                            ref={slider2}
                            afterChange={(newIndex) => slideChange(newIndex)}
                            initialSlide={currentSlide}
                            {...sliderSettingsDefault}
                          >
                            {product.assets.map((item, index) => {
                              return (
                                <div
                                  className={`slider-item ${
                                    currentSlide === index
                                      ? "slick-current"
                                      : ""
                                  }`}
                                  key={index}
                                >
                                  <div className="thumb">
                                    <img
                                      src={item.link}
                                      alt=""
                                      style={{
                                        objectFit: "cover",
                                        height: "78px",
                                        width: "100%",
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </Slider>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="product-info mt-5 me-xxl-5">
                      <h4 className="product-price text-primary">
                        ${product.price}
                      </h4>
                      <h2 className="product-title">{product.name}</h2>
                      <div className="product-excrept text-soft">
                        <p className="lead">{product.desc}</p>
                      </div>
                      <div className="product-meta">
                        <ul className="d-flex g-3 gx-5">
                          <li>
                            <div className="fs-14px text-muted">Category</div>
                            <div className="fs-16px fw-bold text-secondary">
                              {product.category?.name}
                            </div>
                          </li>
                          <li>
                            <div className="fs-14px text-muted">SKU</div>
                            <div className="fs-16px fw-bold text-secondary">
                              {product.sku}
                            </div>
                          </li>
                          <li>
                            <div className="fs-14px text-muted">Weight</div>
                            <div className="fs-16px fw-bold text-secondary">
                              {product.weight}
                            </div>
                          </li>
                          <li>
                            <div className="fs-14px text-muted">Quantity</div>
                            <div className="fs-16px fw-bold text-secondary">
                              {product.quantity}
                            </div>
                          </li>
                        </ul>
                      </div>
                      {product.attributes?.map((attribute, index) =>
                        attribute.type === "color" ? (
                          <div className="product-meta" key={index}>
                            <h6 className="title">{attribute.name}</h6>
                            <ul className="custom-control-group">
                              {attribute.options.map((option, index) => (
                                <li key={index}>
                                  <div className="custom-control color-control">
                                    <span
                                      className="border border-2 custom-control-label dot dot-xxxl"
                                      style={{ background: option }}
                                    ></span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          <div className="product-meta" key={index}>
                            <h6 className="title">{attribute.name}</h6>
                            <ul className="custom-control-group">
                              {attribute.options.map((option, index) => (
                                <li key={index}>
                                  <div className="custom-control custom-radio custom-control-pro no-control">
                                    <span
                                      className="custom-control-label"
                                      htmlFor="sizeCheck1"
                                    >
                                      {option}
                                    </span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Block>
        )}
      </Content>
    </React.Fragment>
  );
};

export default ProductDetailsPage;
