import { createSlice } from "@reduxjs/toolkit";
import * as ForumApi from "../api/forum";
const forums = createSlice({
  name: "forums",
  initialState: {
    loading: false,
    error: null,
    list: [],
    summary: null,
  },
  reducers: {
    setLoading: (state) => {
      state.error = null;
      state.loading = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    getListSuccess: (state, action) => {
      state.list = action.payload.docs;
      state.summary = action.payload.summary;
      state.loading = false;
    },
    addForumSuccess: (state, action) => {
      state.list = [action.payload, ...state.list];
    },
    updateForumSuccess: (state, action) => {
      state.list = state.list.map((forum) => {
        if (forum._id === action.payload._id) {
          return action.payload;
        } else {
          return forum;
        }
      });
    },
    deleteForumSuccess: (state, action) => {
      state.list = state.list.filter(
        (item) => action.payload?._id !== item._id
      );
    },
    reorderForumSuccess: (state, action) => {
      function swapItems(items, fromIndex, toIndex) {
        const newItems = [...items];
        const [removed] = newItems.splice(fromIndex, 1);
        newItems.splice(toIndex, 0, removed);
        return newItems;
      }
      const index1 = state.list.findIndex(
        (item) => item._id === action.payload.id
      );
      const index2 = state.list.findIndex(
        (item) => item._id === action.payload.nextId
      );

      if (index1 !== -1 && index2 !== -1) {
        const newItems = swapItems(state.list, index1, index2);
        state.list = newItems;
      }
    },
  },
});

export const getListForum = (params) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await ForumApi.getListForum(params);
    dispatch(getListSuccess(res));
  } catch (error) {
    dispatch(setError(error));
  }
};

const { reducer, actions } = forums;
export const {
  setLoading,
  setError,
  getListSuccess,
  addForumSuccess,
  deleteForumSuccess,
  updateForumSuccess,
  reorderForumSuccess,
} = actions;
export default reducer;
