import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { updateUser } from "../../../api/user";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateTrainerSuccess } from "../../../slice/trainers";
import { useEffect, useState } from "react";
import { updateUserSuccess } from "../../../slice/users";
import { updateCoachSuccess } from "../../../slice/coaches";

const useUserForm = (user, { type }) => {
  const dispatch = useDispatch();
  const schema = yup
    .object({
      fullName: yup.string().required(),
      email: yup
        .string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          "Invalid email address"
        ),
      role: yup.string().required("Membership type is required"),
      serviceType: yup.string().when(["role"], (role, schema) => {
        return role === "service_provider"
          ? schema.required("Service type is required")
          : schema;
      }),
      dob: yup.string(),
      jobTitle: yup.string(),
      basedIn: yup.string(),
      aboutMe: yup.string(),
      businessName: yup.string(),
      businessEmail: yup
        .string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          "Invalid email address"
        ),
      facebook: yup.string(),
      instagram: yup.string(),
      tiktok: yup.string(),
      youtube: yup.string(),
      twitter: yup.string(),
      website: yup.string(),
      available: yup.array(),
      method: yup.array(),
    })
    .required();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      available: [],
      method: [],
    },
  });
  const { watch, reset } = form;
  const dob = watch("dob");
  const basedIn = watch("basedIn");
  const role = watch("role");

  const [categories, setCategories] = useState();
  const [serviceProvidedTo, setServiceProvidedTo] = useState([]);
  const [trainingCategories, setTrainingCategories] = useState();
  const [mentorCategories, setMentorCategories] = useState();
  const [consultancyCategories, setConsultancyCategories] = useState();
  useEffect(() => {
    reset({
      aboutMe: user?.aboutMe,
      jobTitle: user?.jobTitle,
      basedIn: user?.basedIn,
      role: user?.role,
      email: user?.email,
      businessEmail: user?.businessEmail,
      businessName: user?.businessName,
      dob: user?.dob,
      fullName: user?.fullName,
      serviceType: user?.serviceType,
      facebook: user?.socials?.facebook,
      tiktok: user?.socials?.tiktok,
      instagram: user?.socials?.instagram,
      website: user?.socials?.website,
      youtube: user?.socials?.youtube,
      twitter: user?.socials?.twitter,
      available: user?.available ?? [],
      method: user?.method ?? [],
      serviceProvidedTo: user?.serviceProvidedTo ?? [],
    });
    setCategories(user?.categories);
    setConsultancyCategories(user?.consultancyCategories);
    setTrainingCategories(user?.trainingCategories);
    setMentorCategories(user?.mentorCategories);
    setServiceProvidedTo(user?.serviceProvidedTo);
  }, [reset, user]);
  const onFormSubmit = async (values) => {
    const data = {
      ...values,
      socials: {
        facebook: values.facebook,
        tiktok: values.tiktok,
        instagram: values.instagram,
        website: values.website,
        youtube: values.youtube,
        twitter: values.twitter,
      },
      categories: categories?.map((e) => e._id),
      trainingCategories: trainingCategories?.map((e) => e._id),
      mentorCategories: mentorCategories?.map((e) => e._id),
      consultancyCategories: consultancyCategories?.map((e) => e._id),
      serviceProvidedTo,
    };
    try {
      const res = await updateUser(user?._id, data);
      toast.success("Update user successfully");
      dispatch(
        type === "mentor"
          ? updateCoachSuccess(res)
          : type === "trainer"
          ? updateTrainerSuccess(res)
          : updateUserSuccess(res)
      );
    } catch (error) {
      toast.error(error);
    }
  };
  return {
    form,
    onFormSubmit,
    dob,
    role,
    basedIn,
    categories,
    setCategories,
    trainingCategories,
    setTrainingCategories,
    mentorCategories,
    consultancyCategories,
    setMentorCategories,
    setConsultancyCategories,
    serviceProvidedTo,
    setServiceProvidedTo,
  };
};

export default useUserForm;
