import { createSlice } from "@reduxjs/toolkit";
import * as ProductApi from "../api/product";

const productDetail = createSlice({
  name: "productDetail",
  initialState: {
    loading: false,
    error: null,
    product: null,
  },
  reducers: {
    setLoading: (state) => {
      state.error = null;
      state.loading = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    getDetailSuccess: (state, action) => {
      state.product = action.payload;
      state.loading = false;
    },
    updateProductSuccess: (state, action) => {
      state.product = action.payload;
      state.loading = false;
    },
  },
});

export const getProductDetail = (id) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await ProductApi.getProductById(id);
    dispatch(getDetailSuccess(res));
  } catch (error) {
    dispatch(setError(error));
  }
};

const { reducer, actions } = productDetail;
export const { setLoading, setError, getDetailSuccess, updateProductSuccess } =
  actions;
export default reducer;
