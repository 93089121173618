import axios from "axios";
import { requestError } from "../utils/RequestError";

export const signIn = async (payload) => {
  try {
    const res = await axios.post(`/auth/login`, payload);
    const { userInfo } = res.data["data"];
    if (userInfo.role === "admin") {
      return res.data["data"];
    } else {
      throw new Error("No Permission");
    }
  } catch (error) {
    throw requestError(error, "No Permission");
  }
};
export const changePassword = async (payload) => {
  try {
    const res = await axios.patch(`/users/me/change-password`, payload);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
