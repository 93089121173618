import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { debounce } from "lodash";
import {
  BlockHead,
  BlockTitle,
  BlockHeadContent,
  Icon,
  BlockBetween,
  RSelect,
} from "../../components/Component";
import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Badge,
} from "reactstrap";
import BasePaginationTable from "../../components/table/BasePaginationTable";
import UpdateUserModal from "./modals/UpdateUserModal";
import DeleteConfirmationModal from "../admin/modals/DeleteConfimationModal";
import { defaultParams, membershipTypes } from "../../constants";
import { useDispatch } from "react-redux";
import {
  deleteUserSuccess,
  getListUser,
  updateUserSuccess,
} from "../../slice/users";
import { approveUser, deleteUser } from "../../api/user";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getDetailSuccess } from "../../slice/user-detail";

function getDefaultColumns(
  onEditClick,
  onDeleteClick,
  onApproveClick,
  isApproveLoading
) {
  return [
    {
      head: <span className="sub-text">{"Name"}</span>,
      cell: (row) => <span>{row.fullName}</span>,
    },
    {
      head: <span className="sub-text">{"Email"}</span>,
      cell: (row) => <span>{row.email}</span>,
    },
    {
      head: <span className="sub-text">{"Based In"}</span>,
      cell: (row) => <span>{row.basedIn}</span>,
    },
    {
      head: <span className="sub-text">{"Role"}</span>,
      cell: (row) => (
        <>
          <span>{row.role}</span>
          {row.role === "service_provider" && (
            <>
              <br />
              <span>{row.serviceType}</span>
            </>
          )}
        </>
      ),
    },
    {
      head: <span className="sub-text">{"Status"}</span>,
      cell: (row) => (
        <span>
          <Badge
            className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
            color={row.approved ? "success" : "danger"}
          >
            {row.approved ? "Approved" : "Unapproved"}
          </Badge>
        </span>
      ),
    },
    {
      head: <span className="sub-text"></span>,
      cellClassName: "nk-tb-col-tools",
      cell: (row) => (
        <ul
          className="nk-tb-actions gx-1"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onApproveClick(row);
                      }}
                    >
                      <Icon
                        name={row.approved ? "user-cross" : "user-check"}
                      ></Icon>
                      <span>{row.approved ? "Unapprove" : "Approve"}</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#approved"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onEditClick(row);
                      }}
                    >
                      <Icon name="eye"></Icon>
                      <span>{"Edit"}</span>
                    </DropdownItem>
                  </li>

                  <li>
                    <DropdownItem
                      tag="a"
                      href="#delete"
                      className="link link-danger"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onDeleteClick(row);
                      }}
                    >
                      <Icon name="delete"></Icon>
                      <span>{"Delete"}</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      ),
    },
  ];
}
const UsersPage = () => {
  const { list, summary, loading } = useSelector((state) => state.users);
  const [isApproveLoading, setApproveLoading] = useState("-1");
  const [smOption, setSmOption] = useState(false);
  const navigate = useNavigate();
  const [params, setParams] = useState(defaultParams);
  const dispatch = useDispatch();
  const [isUserModalVisible, setUserModalVisible] = useState({
    user: null,
    update: false,
    delete: false,
  });
  const onEdit = (user) => {
    dispatch(getDetailSuccess(user));
    navigate(`/users/${user?._id}`);
  };
  const closeModal = () => {
    setUserModalVisible({
      user: null,
      delete: false,
      update: false,
    });
  };
  const onDelete = (user) => {
    setUserModalVisible({ user, delete: true });
  };

  useEffect(() => {
    dispatch(getListUser(params));
  }, [dispatch, params]);
  const handlePageChange = (page) => {
    setParams({
      ...params,
      page,
    });
  };
  /// func
  const onDeleteUser = async () => {
    try {
      await deleteUser(isUserModalVisible.user?._id);
      dispatch(deleteUserSuccess(isUserModalVisible.user));
      toast.success("Delete user successfully");
    } catch (error) {
      toast.error(error);
    }
  };
  const approvedCoach = async (user) => {
    setApproveLoading(user?._id);
    try {
      const res = await approveUser(user?._id, {
        approved: !user?.approved,
      });
      toast.success("Update user successfully");
      dispatch(updateUserSuccess(res));
    } catch (error) {
      toast.error(error);
    }
    setApproveLoading("-1");
  };
  const dataTableColumns = getDefaultColumns(
    onEdit,
    onDelete,
    approvedCoach,
    isApproveLoading
  );

  const _handleInputChange = (value) => {
    setParams((prev) => ({
      ...prev,
      keyword: value,
    }));
  };
  const handleInputChange = debounce(_handleInputChange, 500);

  return (
    <React.Fragment>
      <Head title="Users"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Users</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmOption(!smOption);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div
                  className="toggle-expand-content"
                  style={{ display: smOption ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li className="w-250px">
                      <div className="w-100">
                        <RSelect
                          options={membershipTypes}
                          isMulti
                          onChange={(val) => {
                            setParams({
                              ...params,
                              page: 1,
                              roles: val.map((e) => e.value),
                            });
                          }}
                          placeholder="Any Role"
                        />
                      </div>
                    </li>
                    <li>
                      <div
                        className="form-control-wrap"
                        style={{
                          height: "38px",
                        }}
                      >
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control h-100"
                          id="default-04"
                          placeholder="Search by name"
                          onChange={(e) => handleInputChange(e.target.value)}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <BasePaginationTable
          list={list}
          loading={loading}
          columns={dataTableColumns}
          onPageChange={handlePageChange}
          onRowClick={onEdit}
          hasNextPage={summary?.hasNextPage}
          totalItems={summary?.count}
          {...params}
        />
      </Content>
      <UpdateUserModal
        user={isUserModalVisible.user}
        isOpen={isUserModalVisible.update}
        closeModal={closeModal}
      />
      <DeleteConfirmationModal
        onConfirm={onDeleteUser}
        isOpen={isUserModalVisible.delete}
        onCloseModal={closeModal}
      />
    </React.Fragment>
  );
};

export default UsersPage;
