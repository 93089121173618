import React from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  BlockHead,
  BlockTitle,
  BlockHeadContent,
  Button,
  PreviewCard,
  Block,
} from "../../components/Component";
import { Row, Col, Label, Form } from "reactstrap";
import classNames from "classnames";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { blogPosts, roles, userPers } from "../../constants";

const RolePermissionsPage = () => {
  const schema = yup
    .object({
      role: yup.string().required(),
      blogPost: yup.array(),
      user: yup.array(),
    })
    .required();

  const onFormSubmit = (data) => {
    console.log(data);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      blogPost: [],
      user: [],
    },
  });
  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });
  return (
    <React.Fragment>
      <Head title="Role Permissions"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockHeadContent>
            <BlockTitle>Role Permissions</BlockTitle>
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <PreviewCard className="h-100">
            <Form className={formClass} onSubmit={handleSubmit(onFormSubmit)}>
              <Row className="g-gs">
                <Col md="12">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-role">
                      Role
                    </Label>
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <select
                          className="form-control form-select"
                          id="fv-role"
                          {...register("role")}
                          placeholder="Select a option"
                        >
                          {roles.map((role, i) => (
                            <option key={i} label={role} value={role}>
                              {role}
                            </option>
                          ))}
                        </select>
                        {errors.role && (
                          <span className="invalid">{errors.role.message}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <Label className="form-label">Blog Post</Label>
                    <ul className="custom-control-group g-3 align-center">
                      {blogPosts.map((permission, i) => (
                        <li key={permission}>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="form-control custom-control-input"
                              id={"fv-permission " + i}
                              {...register("blogPost")}
                              value={permission}
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor={"fv-permission " + i}
                            >
                              {permission}
                            </Label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <Label className="form-label">User</Label>
                    <ul className="custom-control-group g-3 align-center">
                      {userPers.map((permission, i) => (
                        <li key={permission}>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="form-control custom-control-input"
                              id={"fv-permission " + i}
                              {...register("user")}
                              value={permission}
                            />
                            <Label
                              className="custom-control-label"
                              htmlFor={"fv-permission " + i}
                            >
                              {permission}
                            </Label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <Button color="primary" size="lg" type="submit">
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default RolePermissionsPage;
