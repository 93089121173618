import React from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import DatePicker from "react-datepicker";
import {
  BlockHead,
  BlockTitle,
  BlockHeadContent,
  Button,
  PreviewCard,
  Block,
} from "../../components/Component";
import { Row, Col, Label, Form } from "reactstrap";
import classNames from "classnames";
import useEventForm from "./form/use-event-form";
import { useParams } from "react-router";
import Dropzone from "react-dropzone";

const EditEventPage = () => {
  const { id } = useParams();
  const event = {
    title: "nguyedinhan",
    desc:
      "Excepteur aute sunt officia consequat ipsum enim ex sunt id mollit eu aliquip nulla. Ut excepteur ea enim mollit sunt consequat est ipsum occaecat. Exercitation sint id aliqua ex ad irure labore quis sint Lorem cillum ea adipisicing reprehenderit. Nostrud aute sit ullamco minim voluptate cillum veniam deserunt Lorem velit cillum sit sit proident.",
    from: "2024-01-22T02:36:48.432Z",
    to: "2024-03-22T02:36:48.432Z",
    location: "WK",
  };
  const { from, to, form, onFormSubmit, files, setFiles, handleDropChange } =
    useEventForm(event);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = form;
  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });
  return (
    <React.Fragment>
      <Head title={"Edit Event"}></Head>
      <Content>
        <BlockHead size="sm">
          <BlockHeadContent>
            <BlockTitle>Edit Event</BlockTitle>
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <PreviewCard className="h-100">
            <Form className={formClass} onSubmit={handleSubmit(onFormSubmit)}>
              <Row className="g-gs">
                <Col md="12">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-title">
                      Title
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="fv-title"
                        {...register("title")}
                        className="form-control"
                      />
                      {errors.title && (
                        <span className="invalid">{errors.title.message}</span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-desc">
                      Description
                    </Label>
                    <div className="form-control-wrap">
                      <textarea
                        type="textarea"
                        className="form-control form-control-sm"
                        id="fv-desc"
                        {...register("desc")}
                      />
                      {errors.desc && (
                        <span className="invalid">{errors.desc.message}</span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="from">
                      From
                    </Label>
                    <div className="form-control-wrap">
                      <div className="input-group">
                        <DatePicker
                          selected={
                            Date.parse(from) && new Date(Date.parse(from))
                          }
                          onChange={(date) => {
                            if (date != null)
                              setValue("from", date?.toISOString());
                            else {
                              setValue("from", null);
                            }
                          }}
                          showTimeSelect
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="dd/MM/yyyy h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                      {errors.from && (
                        <span className="invalid">{errors.from.message}</span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="to">
                      To
                    </Label>
                    <div className="form-control-wrap">
                      <div className="input-group">
                        <DatePicker
                          selected={Date.parse(to) && new Date(Date.parse(to))}
                          onChange={(date) => {
                            if (date != null)
                              setValue("to", date?.toISOString());
                            else {
                              setValue("to", null);
                            }
                          }}
                          showTimeSelect
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="dd/MM/yyyy h:mm aa"
                          className="form-control date-picker"
                        />
                      </div>
                      {errors.to && (
                        <span className="invalid">{errors.to.message}</span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-location">
                      Location
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="fv-location"
                        {...register("location")}
                        className="form-control"
                      />
                      {errors.location && (
                        <span className="invalid">
                          {errors.location.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-link">
                      Link
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="fv-link"
                        {...register("link")}
                        className="form-control"
                      />
                      {errors.link && (
                        <span className="invalid">{errors.link.message}</span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col sm="12">
                  <label className="form-label">Image</label>
                  <Dropzone
                    accept={[".jpg", ".jpeg", ".png", ".svg"]}
                    onDrop={(acceptedFiles) =>
                      handleDropChange(acceptedFiles, setFiles)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          className="dropzone upload-zone dz-clickable"
                        >
                          <input {...getInputProps()} />
                          {files.length === 0 && (
                            <div className="dz-message">
                              <span className="dz-message-text">
                                Drag and drop image
                              </span>
                              <span className="dz-message-or">or</span>
                              <Button color="primary" type="button">
                                SELECT
                              </Button>
                            </div>
                          )}
                          {files.map((file) => (
                            <div
                              key={file.name}
                              className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                            >
                              <div className="dz-image">
                                <img
                                  src={file.preview}
                                  alt="preview"
                                  width={120}
                                  height={120}
                                  style={{
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <Button color="primary" size="lg" type="submit">
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default EditEventPage;
