import { createSlice } from "@reduxjs/toolkit";
import * as ProductApi from "../api/product";
const products = createSlice({
  name: "products",
  initialState: {
    loading: false,
    error: null,
    list: [],
    summary: null,
  },
  reducers: {
    setLoading: (state) => {
      state.error = null;
      state.loading = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    getListSuccess: (state, action) => {
      state.list = action.payload.docs;
      state.summary = action.payload.summary;
      state.loading = false;
    },
    addProductSuccess: (state, action) => {
      state.list = [action.payload, ...state.list];
    },
    updateProductSuccess: (state, action) => {
      console.log(action.payload);
      state.list = state.list.map((product) => {
        if (product._id === action.payload._id) {
          return action.payload;
        } else {
          return product;
        }
      });
    },
    deleteProductSuccess: (state, action) => {
      state.list = state.list.filter(
        (item) => action.payload?._id !== item._id
      );
    },
  },
});

export const getListProduct = (params) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await ProductApi.getListProduct(params);
    dispatch(getListSuccess(res));
  } catch (error) {
    dispatch(setError(error));
  }
};

const { reducer, actions } = products;
export const {
  setLoading,
  setError,
  getListSuccess,
  addProductSuccess,
  deleteProductSuccess,
  updateProductSuccess,
} = actions;
export default reducer;
