import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Form, Label, Spinner } from "reactstrap";
import { Icon, Col, Button } from "../../../components/Component";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addForumSuccess, updateForumSuccess } from "../../../slice/forums";
import { addForum, updateForum } from "../../../api/forum";
import { uploadFile } from "../../../api/upload-file";

const UpdateForumModal = ({ isOpen, closeModal, forum }) => {
  const schema = yup
    .object({
      name: yup.string().required("Name is required"),
      desc: yup.string().required("Description is required"),
      image: yup.string().required("Image is required"),
    })
    .required();
  const {
    watch,
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    if (forum) {
      await onUpdateForum(data);
    } else {
      await onAddForum(data);
    }
  };
  const image = watch("image");
  useEffect(() => {
    reset({
      name: forum?.name,
      desc: forum?.desc,
      image: forum?.image,
    });
  }, [reset, forum]);
  ///
  const dispatch = useDispatch();
  const [isLoadingImage, setLoadingImage] = useState(false);
  const uploadImage = async (payload) => {
    setLoadingImage(true);
    try {
      const res = await uploadFile(payload);
      setValue("image", res.fileUrl);
    } catch (error) {
      toast.error(error);
    }
    setLoadingImage(false);
  };
  const onAddForum = async (data) => {
    try {
      const res = await addForum(data);
      dispatch(addForumSuccess(res));
      toast.success("Add forum successfully");
      closeModal();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const onUpdateForum = async (data) => {
    try {
      const res = await updateForum(forum._id, data);
      dispatch(updateForumSuccess(res));
      toast.success("Update forum successfully");
      closeModal();
    } catch (error) {
      toast.error(error?.message);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeModal()}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{forum != null ? "Update" : "Add"} Forum</h5>
          <div className="mt-4">
            <Form
              className="row gy-4 form-validate"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      {...register("name")}
                      placeholder="Enter name"
                    />
                    {errors.name && (
                      <span className="invalid">{errors.name.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <Label className="form-label" htmlFor="fv-desc">
                    Description
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      id="fv-desc"
                      className="form-control"
                      type="text"
                      {...register("desc")}
                      placeholder="Enter desc"
                    />
                    {errors.desc && (
                      <span className="invalid">{errors.desc.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col sm="12">
                <div className="form-group">
                  <label className="form-label">Image</label>
                  <div className="form-control-wrap">
                    <input
                      type="file"
                      id="image"
                      multiple={false}
                      hidden
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => {
                        if (e.target.files) {
                          const file = e.target.files[0];
                          const data = new FormData();
                          data.append("image", file);
                          uploadImage(data);
                        }
                      }}
                    />
                    <label htmlFor="image">
                      {isLoadingImage ? (
                        <div className="w-150px h-150px d-flex align-items-center justify-content-center border">
                          <Spinner />
                        </div>
                      ) : (
                        <div className="w-150px h-150px d-flex align-items-center justify-content-center border">
                          {image ? (
                            <img
                              src={image}
                              width={150}
                              height={150}
                              alt="image"
                              style={{
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <Icon
                              name="upload"
                              style={{
                                fontSize: "30px",
                              }}
                            />
                          )}
                        </div>
                      )}
                    </label>
                  </div>
                  {errors.image && !image && (
                    <span className="invalid-message">
                      {errors.image.message}
                    </span>
                  )}
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {isSubmitting ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default UpdateForumModal;
