const communicationMethods = ["Online", "Face-To-Face"];
const locations = ["WW", "WK", "VI"];
const roles = [
  "Events Manager",
  "Forum Manager",
  "Service Provider Manager",
  "Shop Manager",
  "Admin",
];
const membershipTypes = [
  // { label: "MI Team", value: "mi-team" },
  { label: "Tulip", value: "tulip" },
  { label: "Orchid", value: "orchid" },
  { label: "Dhalia", value: "dhalia" },
  { label: "Service Provider", value: "service_provider" },
  { label: "Admin", value: "admin" },
];
const serviceTypes = [
  { label: "Coaches/Mentors", value: "mentor" },
  { label: "Consultants/Trainers", value: "trainer" },
];

const userPers = ["Change User Password"];
const defaultParams = {
  page: 1,
  limit: 10,
};
const modules = [
  "dashboard",
  "mi services",
  "admin",
  "events",
  "fourm",
  "roles",
];
const categoryTypes = [
  {
    label: "General",
    value: "general",
  },
  {
    label: "Coaching Training",
    value: "coaching-training",
  },
  {
    label: "Soft Skills Coaching Training",
    value: "soft-skills-coaching-training",
  },
  {
    label: "Service Consultancy",
    value: "service-consultancy",
  },
  {
    label: "Shop",
    value: "shop",
  },
];
const blogPosts = ["Create Post", "Update Post", "Delete Post"];
export {
  communicationMethods,
  defaultParams,
  locations,
  modules,
  roles,
  blogPosts,
  userPers,
  categoryTypes,
  membershipTypes,
  serviceTypes,
};
