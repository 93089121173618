import React, { useEffect } from "react";
import { Modal, ModalBody, Form, Label } from "reactstrap";
import { Icon, Col, Button } from "../../../components/Component";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { modules } from "../../../constants";

const UpdateRoleModal = ({ isOpen, closeModal, role }) => {
  const schema = yup
    .object({
      name: yup.string().required("Name is required"),
      modules: yup
        .array()
        .required("Modules is required")
        .min(1, "Modules is required"),
    })
    .required();
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    console.log(data);
  };
  useEffect(() => {
    reset({
      name: role?.name,
      modules: role?.modules ?? [],
    });
  }, [reset, role]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => closeModal()}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{role != null ? "Update" : "Add"} Role</h5>
          <div className="mt-4">
            <Form
              className="row gy-4 form-validate is-alter"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control"
                      type="text"
                      {...register("name")}
                      placeholder="Enter name"
                    />
                    {errors.name && (
                      <span className="invalid">{errors.name.message}</span>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <Label className="form-label">Modules</Label>
                  <ul className="custom-control-group g-3 align-center">
                    {modules.map((module, i) => (
                      <li key={module}>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="form-control custom-control-input"
                            id={"fv-module " + i}
                            {...register("modules")}
                            value={module}
                          />
                          <Label
                            className="custom-control-label"
                            htmlFor={"fv-module " + i}
                          >
                            {module}
                          </Label>
                          {errors.modules && i === 0 && (
                            <span className="invalid">
                              {errors.modules.message}
                            </span>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      Save
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default UpdateRoleModal;
