const menu = [
  {
    icon: "dashboard-fill",
    text: "Dashboard",
    link: "/",
  },
  {
    icon: "users-fill",
    text: "MI Services",
    link: "/mi-services",
    active: false,
    subMenu: [
      {
        text: "Coaches & Mentor",
        link: "/coaches",
      },
      {
        text: "Consultants & Trainers",
        link: "/consultants",
      },
    ],
  },
  {
    icon: "setting-alt-fill",
    text: "Categories",
    link: "/categories",
  },
  // {
  //   icon: "setting-alt-fill",
  //   text: "Admin",
  //   active: false,
  //   subMenu: [
  //     // {
  //     //   text: "Roles",
  //     //   link: "/roles",
  //     // },
  //     // {
  //     //   text: "Role Permissions",
  //     //   link: "/role-permissions",
  //     // },
  //     // {
  //     //   text: "Sub Admin",
  //     //   link: "/administrators",
  //     // },
  //     {
  //       text: "JotForms",
  //       link: "/jot-forms",
  //     },
  //     {
  //       text: "Sign Up Categories",
  //       link: "/categories",
  //     },
  //   ],
  // },
  {
    icon: "alarm-alt",
    text: "Events",
    link: "/events",
  },
  {
    icon: "list-fill",
    text: "Forums",
    link: "/forums",
  },
  {
    icon: "home-fill",
    text: "Shops",
    active: false,
    subMenu: [
      {
        text: "Products",
        link: "/products",
      },
    ],
  },
  {
    icon: "user-list",
    text: "Manage Users",
    link: "/users",
  },
  {
    icon: "img-fill",
    text: "Manage Social",
    link: "/posts",
  },
  // {
  //   icon: "col-3s",
  //   text: "Subscriptions",
  //   link: "/subscriptions",
  // },
  {
    icon: "report",
    text: "Reports",
    link: "/reports",
  },
];
export default menu;
