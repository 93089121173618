import axios from "axios";
import { requestError } from "../utils/RequestError";

export const addEvent = async (payload) => {
  try {
    const res = await axios.post(`/admin/events`, payload);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const getListEvent = async (params) => {
  try {
    const res = await axios.get(`/events`, { params });
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};

export const updateEvent = async (id, payload) => {
  try {
    const res = await axios.put(`/admin/events/${id}`, payload);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
export const deleteEvent = async (id) => {
  try {
    const res = await axios.delete(`/admin/events/${id}`);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
