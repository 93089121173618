import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../slice/auth";

const Layout = ({ title, ...props }) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!accessToken) {
      dispatch(logout());
      navigate("/login", {
        replace: true,
      });
    }
  }, [accessToken, dispatch, navigate]);

  return (
    <>
      <Head title={!title && "Loading"} />
      <AppRoot>
        <AppMain>
          <Sidebar fixed />
          <AppWrap>
            <Header fixed />
            <Outlet />
          </AppWrap>
        </AppMain>
      </AppRoot>
    </>
  );
};
export default Layout;
