import axios from "axios";
import { requestError } from "../utils/RequestError";

export const uploadFile = async (payload, options) => {
  try {
    const res = await axios.post(`/file-upload/image`, payload, options);
    return res.data["data"];
  } catch (error) {
    throw requestError(error);
  }
};
