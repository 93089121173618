import React, { useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Badge } from "reactstrap";
import {
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  Icon,
} from "../../components/Component";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
} from "reactstrap";
import BasePaginationTable from "../../components/table/BasePaginationTable";
import moment from "moment";
import UpdateAdminModal from "./modals/UpdateAdminModal";
import DeleteConfirmationModal from "./modals/DeleteConfimationModal";
function getDefaultColumns(onEditClick, onDeleteClick) {
  return [
    {
      head: <span className="sub-text">{"Name"}</span>,
      cell: (row) => <span>{row.name}</span>,
    },
    {
      head: <span className="sub-text">{"Email"}</span>,
      cell: (row) => <span>{row.email}</span>,
    },

    {
      head: <span className="sub-text">{"Address"}</span>,
      cell: (row) => <span>{row.address}</span>,
    },
    {
      head: <span className="sub-text">{"Role"}</span>,
      cell: (row) => <span>{row.role}</span>,
    },
    {
      head: <span className="sub-text">{"Joined Date"}</span>,
      cell: (row) => (
        <span>{moment(row.createdAt).format("DD/MM/yyyy hh:mm")}</span>
      ),
    },
    {
      head: <span className="sub-text">{"Status"}</span>,
      cell: (row) => (
        <span>
          <Badge
            className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
            color={row.verified ? "success" : "warning"}
          >
            {row.verified ? "Deactive" : "Active"}
          </Badge>
        </span>
      ),
    },
    {
      head: <span className="sub-text"></span>,
      cellClassName: "nk-tb-col-tools",
      cell: (row) => (
        <ul
          className="nk-tb-actions gx-1"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onEditClick(row);
                      }}
                    >
                      <Icon name="eye"></Icon>
                      <span>{"Edit"}</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#delete"
                      className="link link-danger"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onDeleteClick(row);
                      }}
                    >
                      <Icon name="delete"></Icon>
                      <span>{"Delete"}</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      ),
    },
  ];
}
const AdminsPage = () => {
  const [smOption, setSmOption] = useState(false);
  const onDelete = (admin) => {
    setAdminModalVisible({ admin, delete: true });
  };
  const list = [
    {
      name: "nguyedinhan",
      email: "nguyedinhan@gmail.com",
      role: "Forum Manager",
      createdAt: "2024-01-22T02:36:48.432Z",
      address: "3445 Bubby Drive",
      verified: false,
    },
    {
      name: "nguyedinhan",
      email: "nguyedinhan@gmail.com",
      role: "Forum Manager",
      createdAt: "2024-01-22T02:36:48.432Z",
      address: "3445 Bubby Drive",
      verified: false,
    },
    {
      name: "nguyedinhan",
      email: "nguyedinhan@gmail.com",
      role: "Service Provider Manager",
      createdAt: "2024-01-22T02:36:48.432Z",
      address: "3445 Bubby Drive",
      verified: false,
    },
    {
      name: "nguyedinhan",
      email: "nguyedinhan@gmail.com",
      role: "Service Provider Manager",
      createdAt: "2024-01-22T02:36:48.432Z",
      address: "3445 Bubby Drive",
      verified: false,
    },
    {
      name: "nguyedinhan",
      email: "nguyedinhan@gmail.com",
      role: "Service Provider Manager",
      createdAt: "2024-01-22T02:36:48.432Z",
      address: "3445 Bubby Drive",
      verified: false,
    },
    {
      name: "nguyedinhan",
      email: "nguyedinhan@gmail.com",
      role: "Service Provider Manager",
      createdAt: "2024-01-22T02:36:48.432Z",
      address: "3445 Bubby Drive",
      verified: false,
    },
  ];
  const [isAdminModalVisible, setAdminModalVisible] = useState({
    admin: null,
    update: false,
    delete: false,
  });

  const openModal = (admin) => {
    setAdminModalVisible({ admin, update: true });
  };
  const closeModal = () => {
    setAdminModalVisible({
      admin: null,
      delete: false,
      update: false,
    });
  };
  const dataTableColumns = getDefaultColumns(openModal, onDelete);
  return (
    <React.Fragment>
      <Head title="Admins"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Admins</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmOption(!smOption);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div
                  className="toggle-expand-content"
                  style={{ display: smOption ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        color="primary"
                        onClick={() => {
                          openModal();
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button>
                      <Button
                        className="toggle d-none d-md-inline-flex"
                        color="primary"
                        onClick={() => {
                          openModal();
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Admin</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <BasePaginationTable
          list={list}
          columns={dataTableColumns}
          hasNextPage={false}
          totalItems={list.length}
        />
      </Content>
      <UpdateAdminModal
        admin={isAdminModalVisible.admin}
        isOpen={isAdminModalVisible.update}
        closeModal={closeModal}
      />
      <DeleteConfirmationModal
        isOpen={isAdminModalVisible.delete}
        onCloseModal={closeModal}
      />
    </React.Fragment>
  );
};

export default AdminsPage;
