import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
  BlockHeadContent,
  BlockTitle,
  BlockBetween,
  BlockHead,
  Icon,
  Block,
  DataTableHead,
  DataTableRow,
  PreviewAltCard,
  PaginationComponent,
} from "../../components/Component";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Badge,
  Button,
} from "reactstrap";
import UpdateForumModal from "./modals/UpdateForumModal";
import DeleteConfirmationModal from "../admin/modals/DeleteConfimationModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteForumSuccess,
  getListForum,
  reorderForumSuccess,
} from "../../slice/forums";
import { deleteForum, reorderForum } from "../../api/forum";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ListSkeletonLoading from "../../components/skeleton/ListSkeletonLoading";
function getDefaultColumns(onEditClick, onDeleteClick) {
  return [
    {
      head: <span className="sub-text"></span>,
      cell: (row) => <Icon name="view-row-wd" className="fs-5 me-3" />,
    },
    {
      head: <span className="sub-text">{"Name"}</span>,
      cell: (row) => <span>{row.name}</span>,
    },
    {
      head: <span className="sub-text">{"Description"}</span>,
      cell: (row) => <span>{row.desc}</span>,
    },

    {
      head: <span className="sub-text">{"Image"}</span>,
      cell: (row) => (
        <img
          src={row.image}
          alt="forum-img"
          width={100}
          height={100}
          style={{
            objectFit: "cover",
          }}
        />
      ),
    },
    {
      head: <span className="sub-text">{"Total Members"}</span>,
      cell: (row) => <span>{(row.members ?? []).length}</span>,
    },
    {
      head: <span className="sub-text">{"Total Favorite"}</span>,
      cell: (row) => <span>{row.totalLikes}</span>,
    },
    {
      head: <span className="sub-text">{"Status"}</span>,
      cell: (row) => (
        <span>
          <Badge
            className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
            color={row.active ? "success" : "warning"}
          >
            {row.active ? "Active" : "Deactive"}
          </Badge>
        </span>
      ),
    },
    {
      head: <span className="sub-text"></span>,
      cellClassName: "nk-tb-col-tools",
      cell: (row) => (
        <ul
          className="nk-tb-actions gx-1"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onEditClick(row);
                      }}
                    >
                      <Icon name="eye"></Icon>
                      <span>{"Edit"}</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      className="link link-danger"
                      href="#delete"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onDeleteClick(row);
                      }}
                    >
                      <Icon name="delete"></Icon>
                      <span>{"Delete"}</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      ),
    },
  ];
}
const ForumsPage = () => {
  const [smOption, setSmOption] = useState(true);
  const { list, summary, loading } = useSelector((state) => state.forums);
  const [params, setParams] = useState({ page: 1, limit: 50 });
  const dispatch = useDispatch();

  const [isForumModalVisible, setForumModalVisible] = useState({
    forum: null,
    update: false,
    delete: false,
  });
  const openModal = (forum) => {
    setForumModalVisible({ forum, update: true });
  };
  const closeModal = () => {
    setForumModalVisible({
      forum: null,
      delete: false,
      update: false,
    });
  };
  const onDelete = (forum) => {
    setForumModalVisible({ forum, delete: true });
  };
  const dataTableColumns = getDefaultColumns(openModal, onDelete);
  useEffect(() => {
    dispatch(getListForum(params));
  }, [dispatch, params]);
  const handlePageChange = (page) => {
    setParams({
      ...params,
      page,
    });
  };
  /// func
  const onDeleteForum = async () => {
    try {
      await deleteForum(isForumModalVisible.forum?._id);
      dispatch(deleteForumSuccess(isForumModalVisible.forum));
      toast.success("Delete forum successfully");
    } catch (error) {
      toast.error(error);
    }
  };

  const onReorderForum = async (payload) => {
    try {
      await reorderForum(payload);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <React.Fragment>
      <Head title="Forums"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Forums</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmOption(!smOption);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div
                  className="toggle-expand-content"
                  style={{ display: smOption ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        color="primary"
                        onClick={() => {
                          openModal();
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button>
                      <Button
                        className="toggle d-none d-md-inline-flex"
                        color="primary"
                        onClick={() => {
                          openModal();
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Forum</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DragDropContext
            onDragEnd={(droppedItem) => {
              console.log(droppedItem);
              if (!droppedItem.destination) return;
              var nextIdIndex = droppedItem.destination.index;
              var data = {};
              data.id = droppedItem.draggableId;
              data.nextId = list[nextIdIndex]._id;
              dispatch(reorderForumSuccess(data));
              onReorderForum(data);
            }}
          >
            <Droppable droppableId="forum-list">
              {(provided) => (
                <div
                  className="nk-tb-list is-separate is-medium mb-3 forum-list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <DataTableHead className="nk-tb-item">
                    {dataTableColumns.map((column, index) => (
                      <DataTableRow
                        key={index}
                        className={column.headClassName}
                      >
                        {column.head}
                      </DataTableRow>
                    ))}
                  </DataTableHead>

                  {loading ? (
                    <ListSkeletonLoading
                      limit={params.limit}
                      columns={dataTableColumns}
                    />
                  ) : (
                    list &&
                    list.length > 0 &&
                    list.map((item, index) => (
                      <Draggable
                        key={item._id}
                        draggableId={item._id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="nk-tb-item"
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            {dataTableColumns.map((column, index) => (
                              <DataTableRow
                                key={index}
                                onClick={
                                  column.onClick
                                    ? () => column.onClick(item)
                                    : null
                                }
                                className={column.cellClassName}
                              >
                                {column.cell(item)}
                              </DataTableRow>
                            ))}
                          </div>
                        )}
                      </Draggable>
                    ))
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {list && list?.length === 0 && !loading ? (
            <PreviewAltCard>
              <div className="text-center">
                <span className="text-gray">{"No Data Found"}</span>
              </div>
            </PreviewAltCard>
          ) : params?.page === 1 && !summary?.hasNextPage ? null : (
            <PreviewAltCard>
              <PaginationComponent
                totalItems={summary?.count}
                itemPerPage={params.limit ?? 10}
                paginate={handlePageChange}
                currentPage={params.page ?? 1}
              />
            </PreviewAltCard>
          )}
        </Block>
      </Content>
      <UpdateForumModal
        forum={isForumModalVisible.forum}
        isOpen={isForumModalVisible.update}
        closeModal={closeModal}
      />
      <DeleteConfirmationModal
        onConfirm={onDeleteForum}
        isOpen={isForumModalVisible.delete}
        onCloseModal={closeModal}
      />
    </React.Fragment>
  );
};

export default ForumsPage;
