import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import DatePicker from "react-datepicker";
import {
  BlockHead,
  BlockTitle,
  BlockHeadContent,
  Button,
  PreviewCard,
  Block,
  RSelect,
} from "../../components/Component";
import { Row, Col, Label, Form, Spinner } from "reactstrap";
import classNames from "classnames";
import useUserForm from "./form/use-user-form";
import { categoryTypes, membershipTypes, serviceTypes } from "../../constants";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { getUserDetail } from "../../slice/user-detail";
import { useSelector } from "react-redux";
import { countries } from "../../constants/Country";
import { getCategories } from "../../api/category";

const EditUserPage = ({ type }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserDetail(id));
  }, [id, dispatch]);
  const { user } = useSelector((state) => state.userDetail);

  const {
    dob,
    role,
    basedIn,
    form,
    onFormSubmit,
    categories,
    setCategories,
    trainingCategories,
    setTrainingCategories,
    mentorCategories,
    consultancyCategories,
    setMentorCategories,
    setConsultancyCategories,
    serviceProvidedTo,
    setServiceProvidedTo,
  } = useUserForm(user, {
    type,
  });
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = form;
  const serviceType = watch("serviceType");
  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });
  const [categoryOpts, setCategoryOpts] = useState([]);

  const generalCategoryOpts = categoryOpts?.filter((e) => e.type === "general");
  const mentorCategoryOpts = categoryOpts?.filter(
    (e) => e.type === "coaching-training"
  );
  const trainingCategoryOpts = categoryOpts?.filter(
    (e) => e.type === "soft-skills-coaching-training"
  );
  const consultancyCategoryOpts = categoryOpts?.filter(
    (e) => e.type === "service-consultancy"
  );

  const initCategories = async () => {
    try {
      const res = await getCategories({
        types: categoryTypes.map((e) => e.value),
      });
      setCategoryOpts(res);
    } catch (error) {}
  };
  useEffect(() => {
    initCategories();
  }, []);

  return (
    <React.Fragment>
      <Head
        title={
          type === "mentor"
            ? "Edit Coaches & Mentors"
            : type === "trainer"
            ? "Edit Consultants & Trainers"
            : "Edit Users"
        }
      ></Head>
      <Content>
        <BlockHead size="sm">
          <BlockHeadContent>
            <BlockTitle>
              {type === "mentor"
                ? "Edit Coaches & Mentors"
                : type === "trainer"
                ? "Edit Consultants & Trainers"
                : "Edit Users"}
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <PreviewCard className="h-100">
            <Form className={formClass} onSubmit={handleSubmit(onFormSubmit)}>
              <Row className="g-gs">
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-full-name">
                      Full Name
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="fv-full-name"
                        {...register("fullName")}
                        className="form-control"
                      />
                      {errors.fullName && (
                        <span className="invalid">
                          {errors.fullName.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-email">
                      Email address
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="email"
                        disabled
                        readOnly
                        id="fv-email"
                        {...register("email")}
                        className="form-control"
                      />
                      {errors.email && (
                        <span className="invalid">{errors.email.message}</span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-job">
                      Job Title
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="fv-job"
                        {...register("jobTitle")}
                        className="form-control"
                      />
                      {errors.jobTitle && (
                        <span className="invalid">
                          {errors.jobTitle.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Menbership Type</label>
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <select
                          className="form-control form-select"
                          {...register("role")}
                          placeholder="Select a option"
                        >
                          {membershipTypes.map((role, i) => (
                            <option
                              key={i}
                              label={role.label}
                              value={role.value}
                            >
                              {role.label}
                            </option>
                          ))}
                        </select>
                        {errors.role && (
                          <span className="invalid">{errors.role.message}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                {role === "service_provider" && (
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Service Type</label>
                      <div className="form-control-wrap">
                        <div className="form-control-select">
                          <select
                            className="form-control form-select"
                            {...register("serviceType")}
                            placeholder="Select a option"
                          >
                            {serviceTypes.map((type, i) => (
                              <option
                                key={i}
                                label={type.label}
                                value={type.value}
                              >
                                {type.label}
                              </option>
                            ))}
                          </select>
                          {errors.serviceType && (
                            <span className="invalid">
                              {errors.serviceType.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="dob">
                      Date of Birth
                    </Label>
                    <div className="form-control-wrap">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        id="dob"
                        selected={Date.parse(dob)}
                        className="form-control"
                        onChange={(date) => {
                          setValue("dob", date.toISOString());
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Based In</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={countries}
                        value={
                          basedIn && {
                            value: basedIn,
                            label: basedIn,
                          }
                        }
                        onChange={(e) => setValue("basedIn", e.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Categories</label>
                    <div className="form-control-wrap">
                      <RSelect
                        isSearchable
                        isMulti
                        options={generalCategoryOpts}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option._id}
                        value={categories}
                        onChange={setCategories}
                      />
                    </div>
                  </div>
                </Col>
                {role === "service_provider" && (
                  <>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">
                          Soft Skills/Coaching/Training Categories
                        </label>
                        <div className="form-control-wrap">
                          <RSelect
                            isSearchable
                            isMulti
                            options={trainingCategoryOpts}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option._id}
                            value={trainingCategories}
                            onChange={setTrainingCategories}
                          />
                        </div>
                      </div>
                    </Col>
                    {serviceType === "mentor" ? (
                      <Col md="6">
                        <div className="form-group">
                          <label className="form-label">
                            Coach/Mentor Categories
                          </label>
                          <div className="form-control-wrap">
                            <RSelect
                              isSearchable
                              isMulti
                              options={mentorCategoryOpts}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option._id}
                              value={mentorCategories}
                              onChange={setMentorCategories}
                            />
                          </div>
                        </div>
                      </Col>
                    ) : (
                      <Col md="6">
                        <div className="form-group">
                          <label className="form-label">
                            Consultancy Categories
                          </label>
                          <div className="form-control-wrap">
                            <RSelect
                              isSearchable
                              isMulti
                              options={consultancyCategoryOpts}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option._id}
                              value={consultancyCategories}
                              onChange={setConsultancyCategories}
                            />
                          </div>
                        </div>
                      </Col>
                    )}
                  </>
                )}
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Service Provided to</label>
                    <div className="form-control-wrap">
                      <RSelect
                        isMulti
                        options={countries}
                        value={
                          serviceProvidedTo &&
                          serviceProvidedTo.map((e) => ({ value: e, label: e }))
                        }
                        onChange={(options) =>
                          setServiceProvidedTo(options.map((e) => e.value))
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-aboutMe">
                      About
                    </Label>
                    <div className="form-control-wrap">
                      <textarea
                        type="textarea"
                        className="form-control form-control-sm"
                        id="fv-aboutMe"
                        {...register("aboutMe")}
                      />
                      {errors.aboutMe && (
                        <span className="invalid">
                          {errors.aboutMe.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-businessName">
                      Business name
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        disabled
                        readOnly
                        id="fv-businessName"
                        {...register("businessName")}
                        className="form-control"
                      />
                      {errors.businessName && (
                        <span className="invalid">
                          {errors.businessName.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-businessEmail">
                      Business email
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        disabled
                        readOnly
                        id="fv-businessEmail"
                        {...register("businessEmail")}
                        className="form-control"
                      />
                      {errors.businessEmail && (
                        <span className="invalid">
                          {errors.businessEmail.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-facebook">
                      Facebook
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="fv-facebook"
                        {...register("facebook")}
                        className="form-control"
                      />
                      {errors.facebook && (
                        <span className="invalid">
                          {errors.facebook.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-instagram">
                      Instagram
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="fv-instagram"
                        {...register("instagram")}
                        className="form-control"
                      />
                      {errors.instagram && (
                        <span className="invalid">
                          {errors.instagram.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-tiktok">
                      Tiktok
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="fv-tiktok"
                        {...register("tiktok")}
                        className="form-control"
                      />
                      {errors.tiktok && (
                        <span className="invalid">{errors.tiktok.message}</span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-youtube">
                      Youtube
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="fv-youtube"
                        {...register("youtube")}
                        className="form-control"
                      />
                      {errors.youtube && (
                        <span className="invalid">
                          {errors.youtube.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-twitter">
                      Twitter
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="fv-twitter"
                        {...register("twitter")}
                        className="form-control"
                      />
                      {errors.twitter && (
                        <span className="invalid">
                          {errors.twitter.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <Label className="form-label" htmlFor="fv-website">
                      Website
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="fv-website"
                        {...register("website")}
                        className="form-control"
                      />
                      {errors.website && (
                        <span className="invalid">
                          {errors.website.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>
                {/* <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Days Of Availability</label>
                      <ul className="custom-control-group g-3 align-center">
                        {daysOfWeek.map((day, i) => (
                          <li key={day}>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="form-control custom-control-input"
                                id={"fv-day " + i}
                                {...register("available")}
                                value={day}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={"fv-day " + i}
                              >
                                {day}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Communication method</label>
                      <ul className="custom-control-group g-3 align-center">
                        {communicationMethods.map((method, i) => (
                          <li key={method}>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="form-control custom-control-input"
                                id={"fv-method " + i}
                                {...register("method")}
                                value={method}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={"fv-method " + i}
                              >
                                {method}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Col> */}
                <Col md="12">
                  <div className="form-group">
                    <Button color="primary" size="lg" type="submit">
                      {isSubmitting ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default EditUserPage;
