import axios from "axios";
import { get } from "lodash";
import { logout } from "../slice/auth";

const endpoint = `${process.env.REACT_APP_API_URL}`;

export const configAxios = (store) => {
  axios.defaults.baseURL = endpoint;

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const responseCode = get(error, "response.data.code");

      if (responseCode === 401) {
        store.dispatch(logout());
      }

      return Promise.reject(error);
    }
  );
};
