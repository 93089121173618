import React from "react";
import { DataTableItem, DataTableRow } from "../Component";

const ListSkeletonLoading = ({ limit, columns }) => {
  return (
    <>
      {Array.from(Array(limit ?? 10).keys()).map((key) => (
        <DataTableItem key={key}>
          {columns.map((_, index) => (
            <DataTableRow key={index} className={columns.cellClassName}>
              <span className="placeholder-glow">
                <span className="placeholder placeholder-lg col-12 rounded"></span>
              </span>
            </DataTableRow>
          ))}
        </DataTableItem>
      ))}
    </>
  );
};

export default ListSkeletonLoading;
