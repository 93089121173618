import { createSlice } from "@reduxjs/toolkit";
import * as DashboardApi from "../api/dashboard";

const dashboard = createSlice({
  name: "dashboard",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {
    setLoading: (state) => {
      state.error = null;
      state.loading = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    getDetailSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
  },
});

export const getDashboardDetail = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await DashboardApi.getDashboard();
    dispatch(getDetailSuccess(res));
  } catch (error) {
    dispatch(setError(error));
  }
};

const { reducer, actions } = dashboard;
export const { setLoading, setError, getDetailSuccess } = actions;
export default reducer;
