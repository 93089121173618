import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import {
  BlockHead,
  BlockTitle,
  BlockHeadContent,
  Icon,
  BlockBetween,
} from "../../components/Component";
import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Badge,
} from "reactstrap";
import moment from "moment/moment";
import BasePaginationTable from "../../components/table/BasePaginationTable";
import { useSelector } from "react-redux";
import { defaultParams } from "../../constants";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteCoachSuccess,
  getListCoach,
  updateCoachSuccess,
} from "../../slice/coaches";
import { approveUser, deleteUser } from "../../api/user";
import DeleteConfirmationModal from "../admin/modals/DeleteConfimationModal";
import { getDetailSuccess } from "../../slice/user-detail";

function getDefaultColumns(
  onEditClick,
  onDeleteClick,
  onApproveClick,
  isApproveLoading
) {
  return [
    {
      head: <span className="sub-text">{"Name"}</span>,
      cell: (row) => <span>{row.fullName}</span>,
    },
    {
      head: <span className="sub-text">{"Email"}</span>,
      cell: (row) => <span>{row.email}</span>,
    },
    {
      head: <span className="sub-text">{"Based In"}</span>,
      cell: (row) => <span>{row.basedIn}</span>,
    },
    {
      head: <span className="sub-text">{"Joined Date"}</span>,
      cell: (row) => <span>{moment(row.createdAt).format("DD/MM/yyyy")}</span>,
    },
    {
      head: <span className="sub-text">{"Status"}</span>,
      cell: (row) => (
        <span>
          <Badge
            className="badge-sm badge-dot has-bg d-none d-sm-inline-flex"
            color={row.approved ? "success" : "danger"}
          >
            {row.approved ? "Approved" : "Unapproved"}
          </Badge>
        </span>
      ),
    },
    {
      head: <span className="sub-text"></span>,
      cellClassName: "nk-tb-col-tools",
      cell: (row) => (
        <ul
          className="nk-tb-actions gx-1"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle btn btn-icon btn-trigger"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu end>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onApproveClick(row);
                      }}
                    >
                      <Icon
                        name={row.approved ? "user-cross" : "user-check"}
                      ></Icon>
                      <span>{row.approved ? "Unapprove" : "Approve"}</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onEditClick(row);
                      }}
                    >
                      <Icon name="eye"></Icon>
                      <span>{"Edit"}</span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#delete"
                      className="link link-danger"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onDeleteClick(row);
                      }}
                    >
                      <Icon name="delete"></Icon>
                      <span>{"Delete"}</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      ),
    },
  ];
}
const CoachesPage = () => {
  const navigate = useNavigate();
  const goToDetail = (user) => {
    dispatch(getDetailSuccess(user));
    navigate(`/coaches/${user._id}`);
  };

  const [params, setParams] = useState(defaultParams);
  const [isApproveLoading, setApproveLoading] = useState("-1");
  const dispatch = useDispatch();
  const { list, summary, loading } = useSelector((state) => state.coaches);
  const [isCoachModalVisible, setCoachModalVisible] = useState({
    coach: null,
    delete: false,
  });
  const onDelete = (coach) => {
    setCoachModalVisible({ coach, delete: true });
  };
  const closeModal = () => {
    setCoachModalVisible({
      coach: null,
      delete: false,
    });
  };
  useEffect(() => {
    dispatch(getListCoach(params));
  }, [dispatch, params]);
  const handlePageChange = (page) => {
    setParams({
      ...params,
      page,
    });
  };
  /// func
  const onDeleteCoach = async () => {
    try {
      await deleteUser(isCoachModalVisible.coach?._id);
      dispatch(deleteCoachSuccess(isCoachModalVisible.coach));
      toast.success("Delete coach successfully");
    } catch (error) {
      toast.error(error);
    }
  };
  const approvedCoach = async (coach) => {
    setApproveLoading(coach?._id);
    try {
      const res = await approveUser(coach?._id, {
        approved: !coach?.approved,
      });
      toast.success("Update user successfully");
      dispatch(updateCoachSuccess(res));
    } catch (error) {
      toast.error(error);
    }
    setApproveLoading("-1");
  };

  const dataTableColumns = getDefaultColumns(
    goToDetail,
    onDelete,
    approvedCoach,
    isApproveLoading
  );
  const [smOption, setSmOption] = useState(false);

  const _handleInputChange = (value) => {
    setParams((prev) => ({
      ...prev,
      keyword: value,
    }));
  };
  const handleInputChange = debounce(_handleInputChange, 500);
  return (
    <React.Fragment>
      <Head title="Coaches & Mentor"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Coaches & Mentor</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand me-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSmOption(!smOption);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div
                  className="toggle-expand-content"
                  style={{ display: smOption ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                          <Icon name="search"></Icon>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="default-04"
                          placeholder="Search by name"
                          onChange={(e) => handleInputChange(e.target.value)}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <BasePaginationTable
          list={list}
          loading={loading}
          columns={dataTableColumns}
          onPageChange={handlePageChange}
          hasNextPage={summary?.hasNextPage}
          totalItems={summary?.count}
          onRowClick={goToDetail}
          {...params}
        />
      </Content>
      <DeleteConfirmationModal
        onConfirm={onDeleteCoach}
        isOpen={isCoachModalVisible.delete}
        onCloseModal={closeModal}
      />
    </React.Fragment>
  );
};

export default CoachesPage;
