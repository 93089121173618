import { createSlice } from "@reduxjs/toolkit";
import * as UserApi from "../api/user";

const userDetail = createSlice({
  name: "userDetail",
  initialState: {
    loading: false,
    error: null,
    user: null,
  },
  reducers: {
    setLoading: (state) => {
      state.error = null;
      state.loading = true;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    getDetailSuccess: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    updateUserSuccess: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
  },
});

export const getUserDetail = (id) => async (dispatch) => {
  try {
    dispatch(setLoading());
    const res = await UserApi.getUserById(id);
    dispatch(getDetailSuccess(res));
  } catch (error) {
    dispatch(setError(error));
  }
};

const { reducer, actions } = userDetail;
export const { setLoading, setError, getDetailSuccess, updateUserSuccess } =
  actions;
export default reducer;
